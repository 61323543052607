import Cookies from "js-cookie";
import { profile } from "../../_services/auth.service";
import service from "../../_services/service";
import { serviceHandler, success } from "../common";
import { POSTSUCCESS } from "./types";

// set user settings
export const storeSettings = (data) => async (dispatch) => {
  //let token = JSON.parse(localStorage.getItem("user")).token;
  let token = JSON.parse(Cookies.get("user")).token;
  const url = "/users/settings?authtoken=" + token;
  service.service(url, data, dispatch, "post", (data) => {
    if (data.result && data.isSuccessful) {
      if (data.token && data.token.token) {
        Cookies.set("user", JSON.stringify(data.token),{
          sameSite: "None",
          secure: true,
        });
        // localStorage.setItem("user", JSON.stringify(data.token));
        token = data.token.token;
      }
      profile(token, dispatch);
      success(dispatch, "Settings stored successfully!");
    }
  });
};

export const changePIN = (password, pin, sMessage) => async (dispatch) => {
  serviceHandler(
    POSTSUCCESS,
    "/users/changepin?authtoken=",
    dispatch,
    "post",
    { password, pin },
    sMessage,
    (data) => {
      profile(data.result.updateToken, dispatch);
    },
    { stayWhenError: true }
  );
};

export const setFavorites = (favs) => async (dispatch) => {
  //let token = JSON.parse(localStorage.getItem("user")).token;
  let token = JSON.parse(Cookies.get("user")).token;
  serviceHandler(
    null,
    `/users/favorites?authtoken=`,
    dispatch,
    "post",
    { favorites: favs },
    null,
    () => profile(token, dispatch),
    null,
    null,
    true
  );
};

export const applyForSuperagent = (successMessage) => async (dispatch) => {
  serviceHandler(
    POSTSUCCESS,
    "/users/applyForSuperagent?authtoken=",
    dispatch,
    "post",
    null,
    successMessage,
    null,
    { stayWhenError: true }
  );
};

export const approveSuperagentApplicantion = (userid) => async (dispatch) => {
  serviceHandler(
    POSTSUCCESS,
    "/users/approveSuperagentApplicantion?authtoken=",
    dispatch,
    "post",
    { userid },
    null,
    null,
    { stayWhenError: true }
  );
};
export const rejectSuperagentApplicantion = (userid) => async (dispatch) => {
  serviceHandler(
    POSTSUCCESS,
    "/users/rejectSuperagentApplicantion?authtoken=",
    dispatch,
    "post",
    { userid },
    null,
    null,
    { stayWhenError: true }
  );
};
