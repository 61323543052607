import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { resetPassWordOtp } from "../../../_redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../_common/common";

const ForgotPassword = (props) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const usernameRef = useRef(null);
  const buttonRef = useRef(null);
  const { t } = useTranslation();
  const message = useSelector(({ message }) => message.message);
  const dispatch = useDispatch();
  clearMessage (dispatch);
  const handleReset = () => {
    dispatch(resetPassWordOtp(username, email))
    .then (() => props.changeView ('FORGOT_ENTER_OTP', {mobile: username}))
    .catch (() => {})
    // props.changeView ('FORGOT_ENTER_OTP', {mobile: username})
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      buttonRef.current.click();
    }
  };
  useEffect(() => {
    usernameRef.current.focus();
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  return (
    <div className="itm-form itm-white">
      <h2 className="mb-4">{t("resetPassword")}</h2>
      {message && <p className="itm-error">{message}</p>}
      <div className="mb-3">
        <label htmlFor="username">{t("MobileNumber")}</label>
        <input
          type="text"
          id="username"
          ref={usernameRef}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="email">{t("Email")}</label>
        <input
          type="email"
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <button
        type="submit"
        ref={buttonRef}
        disabled={!username || !email}
        className="w-100 itm-button mt-20"
        onClick={handleReset}
      >
        {t("resetPassword")}
      </button>
      <p
        className="itm-white itm-clickable pt-20 mt-4"
        onClick={() => props.changeView ('LOGIN')}
      >
        {t("loginTitle")}
      </p>
    </div>
  );
};

export default ForgotPassword;
