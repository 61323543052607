import {
  CLEAR,
  TRANSACTIONS,
  USERTRANSACTIONS,
  GETTRANSACTIONENQUIRY,
  MARKASSUCCESS,
  RRAINFO,
  CLEARRRAINFO,
  TRANSACTIONDETAILS,
  RESETPAGE,
  TRANSACTIONCLAIM,
  CLEARDETAILS,
  USERCOMISSIONS,
} from "../../actions/types";

const initialState = {
  transactions: [],
  page: 1,
  totalRows: 0,
  userTransactions: [],
  userCommissions: [],
  enquiry: null,
  close: false,
  rra: undefined,
  details: null,
  claim: null,
};

export const transactionREducer = (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload.result,
        page: action.payload.collection.pageNumber,
        totalRows: action.payload.collection.totalResult,
      };
    case USERTRANSACTIONS:
      return {
        ...state,
        userTransactions: action.payload.result,
        page: action.payload.collection.pageNumber,
        totalRows: action.payload.collection.totalResult,
      };
    case USERCOMISSIONS:
      return {
        ...state,
        userCommissions: action.payload.result,
        page: action.payload.collection.pageNumber,
        totalRows: action.payload.collection.totalResult,
      };
    case GETTRANSACTIONENQUIRY:
      return {
        ...state,
        enquiry: action.payload.result,
        close: action.payload.close || false,
      };
    case MARKASSUCCESS:
      return {
        ...state,
        transactions: action.payload.id
          ? state.transactions.filter((item) => {
              return item.txn_id != action.pyload;
            })
          : state.transactions,
      };
    case TRANSACTIONDETAILS:
      return { ...state, details: action.payload };
    case TRANSACTIONCLAIM:
      return { ...state, claim: action.payload };
    case RRAINFO:
      return { ...state, rra: action.payload };
    case CLEAR:
      return { ...state, page: 1, details: null, claim: null };
    case CLEARDETAILS:
      return { ...state, details: null };
    case RESETPAGE:
      return { ...state, page: 1 };
    case CLEARRRAINFO:
      return { ...state, rra: undefined };
    default:
      return state;
  }
};
