import {
  CANALOPTIONS,
  CANALPRODUCTS,
  CLEAR,
  DECODERS,
  SETDATA,
  VALIDATECANAL,
} from "../actions/types";

export const CanalPlusReducer = (
  state = { products: [], decoders: [], validateInfo: null, data: null },
  action
) => {
  switch (action.type) {
    case DECODERS:
      return { ...state, decoders: action.payload };
    case VALIDATECANAL:
      return { ...state, validateInfo: action.payload };
    case CANALPRODUCTS:
      return { ...state, products: action.payload };
    case SETDATA:
      return { ...state, data: action.payload };
    case CANALOPTIONS:
      return { ...state, options: action.payload };
    case CLEAR:
      return { ...state, validateInfo: null, decoders: [], data: null };
    default:
      return state;
  }
};
