const PageLength = (props) => {
  const onChangeHandler = (e) => props.pageSizeHandler(e.target.value);
  return (
    <select
      onChange={onChangeHandler}
      defaultValue={props.pageSize}
      className="iteme-pagination-button"
      disabled={props.disable && props.pageSize == 10}
    >
      <option value={10}>10</option>
      <option value={20}>20</option>
      <option value={30}>30</option>
      <option value={40}>40</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
      <option value={150}>150</option>
      <option value={200}>200</option>
      <option value={250}>250</option>
      <option value={300}>300</option>
      <option value={1000}>1000</option>
      <option value={2000}>2000</option>
    </select>
  );
};

export default PageLength;
