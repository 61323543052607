import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { searchClickHandler } from "./Dashboard";
const Categories = (props) => {
  const [active, setActive] = useState(0);

  return (
    <div className="dashboard-categories">
      <div>
        {props.categories(props).map((key, i) => {
          return (
            <div
              className={`dashboard-category ${
                active == i ? "dashboard-category-active" : ""
              }`}
              key={i}
              onClick={() => {
                setActive(i);
                searchClickHandler(props, key.name.toLowerCase());
              }}
              onDragEnter={key.drag ? key.drag : null}
              onDragLeave={key.dragLeave ? key.dragLeave : null}
            >
              <span title={key.name}>
                <FontAwesomeIcon icon={key.icon} />
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Categories;
