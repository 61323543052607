import { LISTSUBAGENT,CLEAR } from "../../actions/types";

const initialState = {
  page: 1,
  totalRows: 0,
  sagents: [],
};

export const SuperAreducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTSUBAGENT:
      return {
        ...state,
        sagents: action.payload.result,
        page: action.payload.collection.pageNumber,
        totalRows: action.payload.collection.totalResult,
      };

    case CLEAR:
      return { ...state, page: 1, details: {} };
    default:
      return state;
  }
};
