import Cookies from "js-cookie";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  WALLET,
  PROFILE,
  COMMISSIONSUM,
  PASSWORDFAIL,
  CLEAR,
  RESETPASSWORDOTPSUCCESS,
  OTPVALIDATESUCESS,
  PASSWORDCHANGEDSUCCESS,
  RETYPEPASSWORDFAILED,
} from "../actions/types";

//const user = JSON.parse(localStorage.getItem("user"));
let user = Cookies.get("user") && JSON.parse(Cookies.get("user"));
const initialState = {
  isLoggedIn: false,
  retypePasswordFailed: false,
  user: user ? user : null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        retypePasswordFailed: false,
      };
    case RETYPEPASSWORDFAILED:
      return { ...state, retypePasswordFailed: true };
    default:
      return state;
  }
}

export const walletReducer = (
  state = {
    wallet: JSON.parse(localStorage.getItem("wallet")),
    totalCommission: null,
    profile: JSON.parse(localStorage.getItem("profile")),
  },
  action
) => {
  switch (action.type) {
    case WALLET:
      return { ...state, wallet: action.payload };
    case PROFILE:
      return { ...state, profile: action.payload };
    case COMMISSIONSUM:
      return { ...state, totalCommission: action.payload.total_unsettled || 0 };
    default:
      return state;
  }
};
const initialPassState = { message: null, otp: false };
export const passWordReducer = (state = initialPassState, action) => {
  switch (action.type) {
    case PASSWORDFAIL:
      return {
        ...state,
        message: action.payload.message,
      };
    case RESETPASSWORDOTPSUCCESS:
      return { otp: true };
    case OTPVALIDATESUCESS:
      return { isNew: true, otpInfo: action.payload?.otp };
    case PASSWORDCHANGEDSUCCESS:
      return { state: initialPassState, passWordChanged: true };
    case CLEAR:
      return initialPassState;
    default:
      return state;
  }
};
