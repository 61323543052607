import React, { useState } from "react";
import HomeHeader from "./Header/HomeHeader";
import Login from "./Auth/Login";
import ForgotPassword from "./Auth/ForgotPassword";
import EnterForgotPasswordOTP from "./Auth/EnterForgotPasswordOTP";
import EnterNewPassword from "./Auth/EnterNewPassword";
import "../../translations/i18n";
import i18n from "../../translations/i18n";
import iteme_1_no_bg from "../../images/iteme_logo_1_no_bg.png";

const HomeNew = () => {
  const translationHandler = (data) => {
    i18n.changeLanguage(data);
  };
  return (
    <div>
      <HomeHeader translationHandler={translationHandler} />
      <HomeBody />
    </div>
  );
};

const HomeBody = () => {
  const [aspect, setAspect] = useState("LOGIN");
  const [data, setData] = useState({});
  const changeView = (view, d = {}) => {
    setData ({...data, ...d});
    setAspect(view);
  };
  return (
    <div className="itm-home-bg">
      <div className="container-fluid vh-100 itm-bg-teal-gradient">
        <div className="row h-100">
          <div className="col-md-6 d-flex justify-content-end align-items-end align-items-md-center">
            <div className="p-3 col-lg-6 col-12 text-center">
              <img src={iteme_1_no_bg} /*width="80%"*/ />
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-start align-items-start align-items-md-center">
            <div className="p-3 col-lg-6 col-12">
              {aspect === "LOGIN" && <Login changeView={changeView} data={data} />}
              {aspect === "FORGOT" && <ForgotPassword changeView={changeView} data={data} />}
              {aspect === "FORGOT_ENTER_OTP" && (
                <EnterForgotPasswordOTP changeView={changeView} data={data} />
              )}
              {aspect === "FORGOT_ENTER_NEW_PASSWORD" && (
                <EnterNewPassword changeView={changeView} data={data} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeNew;
