import React, { useState } from "react";
import iteme from "../../../images/iteme_logo_white.png";
import en from "../../../images/lang/en.svg";
import rw from "../../../images/lang/rw.svg";
import { useTranslation } from "react-i18next";

const flags = {
  en: en,
  kin: rw,
};

const Nav = ({ transilation, handleShow }) => {
  //handler change from one language to other
  const languageHandler = (e, value) => {
    transilation(value || e.target.value);
  };
  return (
    <header id="header" className="header">
      <nav
        style={{ top: "50px" }}
        single-page-nav="current"
        offset="75"
        className="header-nav"
      >
        <List languageHandler={languageHandler} handleShow={handleShow} />
      </nav>
    </header>
  );
};

export const List = ({ languageHandler, loggedIn }) => {
  return (
    <ul className={!loggedIn ? "header-main-nav" : "languageHome"}>
      <li>
        <select
          className={!loggedIn ? "select-header" : "select-languageHome"}
          onChange={languageHandler}
        >
          <option defaultValue="en">English</option>
          <option value="kin">Kinyarwanda</option>
        </select>
      </li>
    </ul>
  );
};

export const LanguageSelector = ({ languageHandler, currentLanguage }) => {
  const [curLang, setCurLang] = useState(currentLanguage);
  const li = [
    {
      src: en,
      title: "English",
      onclickHandler: () => {
        languageHandler(null, "en"), setCurLang("en");
      },
    },
    {
      src: rw,
      title: "Ikinyarwanda",
      onclickHandler: () => {
        languageHandler(null, "kin"), setCurLang("kin");
      },
    },
  ];
  return (
    <div className="header-div">
      <div className="language-selector">
        <img src={flags[curLang]} />
        <ul>
          {li.map((item, i) => {
            return (
              <li key={i}>
                <img
                  src={item.src}
                  title={item.title}
                  onClick={item.onclickHandler}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Nav;
