import {
  FAIL,
  ISLOADING,
  POSTSUCCESS,
  TOKENEXPIRED,
  RECEIPT,
  HIDEMODAL,
  TERMS,
  CLEAR,
  REQUESTAPPROVEWARNING,
} from "../actions/types";

const initialState = {
  isLoading: false,
  tokenExpired: false,
  error: false,
  success: false,
  message: "",
  receipt: false,
  transactionId: null,
  passwordChanged: false,
  terms: false,
  redirect: null,
  stayWhenError: false,
  stayOnC: false,
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ISLOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        shortSpinner: action.shortSpinner,
      };
    case TOKENEXPIRED:
      return tokenExp(state, action);
    case FAIL:
      return fail(action);
    case POSTSUCCESS:
      return postSuccess(state, action);
    case RECEIPT:
      return { ...state, receipt: true, transactionId: action.payload };
    case HIDEMODAL:
      return { ...state, error: false, success: false };
    case TERMS:
      return { ...state, terms: action.payload };
    case CLEAR:
      return {
        ...state,
        receipt: false,
        stayWhenError: false,
        postRes: null,
        requestWarning: null,
        requestId: null,
      };
    case REQUESTAPPROVEWARNING:
      return {
        ...state,
        requestWarning: action.payload.message,
        requestId: action.payload.id,
      };
    default:
      return state;
  }
};
const postSuccess = (state, action) => {
  return {
    ...state,
    success: action.payload.success,
    message: action.payload.message,
    passwordChanged: action.payload.passwordChanged,
    redirect: action.payload.redirect,
    stayWhenError: action.payload.stayOnC,
    postRes:
      (Array.isArray(action.payload.result) && action.payload.result[0]?.id
        ? action.payload.result[0]
        : action.payload.result && action.payload.result[1]) ||
      action.payload.result,
  };
};
const tokenExp = (state, action) => {
  return {
    ...state,
    tokenExpired: action.payload.tokenExpired,
    originalRequest: action.payload.originalRequest,
  };
};
const fail = (action) => {
  return {
    error: action.payload.error,
    message: action.payload.message.info || action.payload.message,
    redirect: action.payload.redirect,
    stayWhenError: action.payload.stayWhenError,
  };
};
export default loadingReducer;
