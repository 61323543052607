import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { checkOTP } from "../../../_redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../_common/common";

const EnterForgotPasswordOTP = (props) => {
  const [otp, setOtp] = useState("");
  const otpRef = useRef(null);
  const buttonRef = useRef(null);
  const { t } = useTranslation();
  const message = useSelector(({ message }) => message.message);
  const dispatch = useDispatch();
  clearMessage (dispatch);
  const handleEnterOTP = () => {
    checkOTP (otp, dispatch)
    .then (() => props.changeView ('FORGOT_ENTER_NEW_PASSWORD', {otp: otp}))
    .catch (() => {})
    // props.changeView ('FORGOT_ENTER_NEW_PASSWORD', {otp: otp})
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      buttonRef.current.click();
    }
  };
  useEffect(() => {
    otpRef.current.focus();
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  return (
    <div className="itm-form itm-white">
      <p>{t("OTPSendMessage")}</p>
      {message && <p className="itm-error">{message}</p>}
      <div className="mb-3">
        <label htmlFor="otp">{t("otp")}</label>
        <input
          type="text"
          id="otp"
          ref={otpRef}
          onChange={(e) => setOtp(e.target.value)}
          required
        />
      </div>
      <button
        type="submit"
        ref={buttonRef}
        disabled={!otp}
        className="w-100 itm-button mt-20"
        onClick={handleEnterOTP}
      >
        {t("resetPassword")}
      </button>
      <p
        className="itm-white itm-clickable pt-20 mt-4"
        onClick={() => props.changeView ('LOGIN')}
      >
        {t("loginTitle")}
      </p>
    </div>
  );
};

export default EnterForgotPasswordOTP;
