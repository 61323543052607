import {
  CLEAR,
  CLEARPAGE,
  RRAFEE,
  RRAPROVIDERS,
  VALIDATERRA,
} from "../actions/types";

const initialState = {
  validateInfo: null,
  feeInfo: null,
  providers:[]
};

const RRAReducer = (state = initialState, action) => {
  switch (action.type) {
    case VALIDATERRA:
      return {
        ...state,
        validateInfo: action.payload,
      };
    case RRAFEE:
      return {
        ...state,
        feeInfo: action.payload,
      };
    case RRAPROVIDERS:
      return { ...state, providers: action.payload };
    case CLEAR:
      return { ...state, validateInfo: null, feeInfo: null };
    default:
      return state;
  }
};

export default RRAReducer;
