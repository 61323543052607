import React, { useState } from "react";
import { Modal, InputGroup, FormControl } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MobileInput, PasswordInput } from "./LoginForm";
import { changePassOtp, resetPassWordOtp } from "../../_redux/actions/auth";
import { otp as otpAction } from "../../_redux/actions/auth";
import { useTranslation } from "react-i18next";
import { ItemeModal } from "../_common/Modal";
import { clearMessage } from "../_common/common";
import { CLEAR } from "../../_redux/actions/types";
const otpTitle = "OTP has been sent to your registered email";
const reset = "Reset Password";
const otpValidate = (otp, dispatch) => {
  if (otp) dispatch(otpAction(otp));
};
export default function ForgotPassWord(props) {
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const message = useSelector(({ message }) => message.message);
  const [otp, setOtp] = useState("");
  const [requiredEr, setRequiredEr] = useState("");
  return (
    <ForgotPasswordRow
      {...props}
      requiredEr={requiredEr}
      setRequiredEr={setRequiredEr}
      mobileNo={mobileNo}
      email={email}
      setEmail={setEmail}
      setMobileNo={setMobileNo}
      otp={otp}
      setOtp={setOtp}
      message={message}
    />
  );
}

const ForgotPasswordRow = (props) => {
  return (
    <ItemeModal
      show={props.show}
      size={props.isOtp ? "mm" : "sm"}
      backdrop="static"
      centered={true}
      hideHandler={props.onHide}
      fphandler={props.fphandler}
      body={<Body {...props} />}
      footer={
        !props.isNewPass ? (
          <Footer {...props} otpValidate={otpValidate} />
        ) : null
      }
    />
  );
};
const Body = (props) => {
  let row = <FormUI {...props} />;
  if (props.isOtp) row = <OTP otp={props.otp} setOtp={props.setOtp} />;
  if (props.isNewPass) row = <NewPassWord otp={props.otp} />;
  return (
    <>
      <h3 className="loginTitle">{props.isOtp ? otpTitle : reset}</h3>
      <h4 className="error">{props.message || props.requiredEr}</h4>
      {row}
    </>
  );
};
const NewPassWord = ({ otp }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [passConfirm, setPassConfirm] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const passWordReducer = useSelector(({ passWordReducer }) => passWordReducer);
  const changePassword = () => {
    if (password && passConfirm && password === passConfirm) {
      setError("");
      dispatch(changePassOtp(password, otp,passWordReducer.otpInfo?.mobile_no));
    } else setError(t("restPassErrorMessage"));
  };
  return (
    <NewPassWordRow
      changePassword={changePassword}
      password={password}
      setPassword={setPassword}
      passConfirm={passConfirm}
      setPassConfirm={setPassConfirm}
      error={error}
    />
  );
};
const row = (props) => {
  const items = [
    {
      password: props.password,
      onChangeHandler: (e) => props.setPassword(e.target.value),
      placeholder: "Enter new password",
    },
    {
      password: props.passConfirm,
      onChangeHandler: (e) => props.setPassConfirm(e.target.value),
      placeholder: "Confirm password",
    },
  ];
  return items;
};
const NewPassWordRow = (props) => {
  const content = row(props);
  return (
    <>
      <h3 className="error text-center">{props.error}</h3>
      {content.map((item, i) => {
        return (
          <PasswordInput
            password={item.password}
            onChangePass={item.onChangeHandler}
            placeholder="Enter new password"
            onkeyPressHandler={props.changePassword}
            key={i}
          />
        );
      })}
      <button
        title="Submit"
        className="iteme-button"
        style={{ width: "100%" }}
        onClick={props.changePassword}
      >
        Submit{" "}
      </button>
    </>
  );
};
const Footer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  clearMessage(dispatch);
  const resetPassWord = () => {
    if (props.mobileNo && props.email) {
      dispatch(resetPassWordOtp(props.mobileNo, props.email));
      props.setRequiredEr("");
    } else props.setRequiredEr("both phone number and email are required");
  };
  return (
    <Modal.Footer className="d-flex justify-content-center">
      <small
        className="link"
        onClick={() => {
          props.isOtp ? dispatch({ type: CLEAR }) : null;
          props.fphandler({});
        }}
      >
        {t("loginButton")}
      </small>
      <button
        title="Login"
        className="iteme-button"
        style={{ width: "100%" }}
        onClick={
          !props.isOtp
            ? resetPassWord
            : () => props.otpValidate(props.otp, dispatch)
        }
      >
        {t("continue")}
      </button>
    </Modal.Footer>
  );
};

const FormUI = ({ email, mobileNo, setEmail, setMobileNo }) => {
  return (
    <>
      <MobileInput
        onChangeMobileNo={(e) => setMobileNo(e.target.value)}
        mobileNo={mobileNo}
      />
      <InputGroup size="lg" className="inputGroup">
        <InputGroup.Text id="inputGroup-sizing-lg" className="inputIcon">
          <FontAwesomeIcon icon={faMailBulk} />
        </InputGroup.Text>
        <FormControl
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="input"
        />
      </InputGroup>
    </>
  );
};
const OTP = ({ otp, setOtp }) => {
  const dispatch = useDispatch();
  clearMessage(dispatch);
  return (
    <MobileInput
      onChangeMobileNo={(e) => setOtp(e.target.value)}
      mobileNo={otp}
      placeholder="Enter OTP"
    />
  );
};
