export const ADMIN_RIGHTS = {
  UNDEFINED_1: {
    id: 0,
    description: "User can make transactions on the platform as an agent",
  },
  VIEW_USERS: {
    id: 1,
    description: "User can view all other users on the platform",
  },
  CREATE_USERS: {
    id: 2,
    description: "User can create new users on the platform",
  },
  APPROVE_USERS: {
    id: 3,
    description: "User can view KCY data of new users and approve them",
  },
  EDIT_USERS: {
    id: 4,
    description: "User can change information of other users",
  },
  APPROVE_EDIT: {
    id: 5,
    description: "User can approve the changes made on another user",
  },
  BAN_USERS: { id: 6, description: "User can ban other users" },
  APPROVE_BAN: {
    id: 7,
    description: "User can approve the banning of another user",
  },
  DELETE_USERS: {
    id: 8,
    description: "User can delete other users from the platform",
  },
  APPROVE_DELETE: { id: 9, description: "User can approve a deletion" },
  MANAGE_RIGHTS: {
    id: 10,
    description: "User can define access rights for other users",
  },
  APPROVE_CREDIT_REQUESTS: {
    id: 11,
    description: "User can approve requests to credit other users wallets",
  },
  APPROVE_CASHOUT_REQUESTS: {
    id: 12,
    description:
      "User can approve requests to deduct floats and cash out from other users wallets",
  },
  VIEW_USER_WALLETS: {
    id: 13,
    description: "User can see the wallet status of other users",
  },
  VIEW_USER_TRANSACTIONS: {
    id: 14,
    description: "User can see the transaction history of other users",
  },
  VIEW_USER_COMMISSIONS: {
    id: 15,
    description: "User can see the commissions of other users",
  },
  VIEW_SYSTEM_COMMISSIONS: {
    id: 16,
    description: "User can see the commissions earned by the system",
  },
  ACCOUNTS_MANAGEMENT: {
    id: 17,
    description: "User can transfer funds between our own accounts",
  },
  CREATE_CREDIT_REQUESTS: {
    id: 18,
    description: "User can create a credit request in the name of an agent",
  },
  MANAGE_CLAIMS: {
    id: 19,
    description: "User can view all claims and assign them to other users",
  },
  IS_TSM: {
    id: 20,
    description: "User manages/supervises other user as teritory sales manager",
  },
  MAKE_WALLET_CORRECTIONS: {
    id: 21,
    description:
      "User can manually make corrections on wallets and transactions.",
  },
  MANAGE_TRAINING_VIDEOS: {
    id: 22,
    description: "User can see and update training videos.",
  },
  EXPERIMENTAL: {
    id: 23,
    description: "User can access expremental features.",
  },
  FLOW_ADMIN: {
    id: 24,
    description: "User can see flow transactions and users.",
  },
  PAY_ADMIN: { id: 25, description: "User can see iteme pay transactions." },
  MANAGE_SERVICES: {
    id: 26,
    description: "User can edit, activate and deactivate services.",
  },
  MANAGE_MESSAGES: { id: 27, description: "User can write and edit messages." },
  MANAGE_BRANDING_PRODUCTS: {
    id: 28,
    description: "User can create and edit branding products.",
  },
  MANAGE_BRANDING_ORDERS: {
    id: 29,
    description: "User can see and update branding orders.",
  },
  IS_SUPERADMIN: {
    id: 30,
    description:
      "User can perform system admin tasks or experimental admin tasks.",
  },
};
Object.keys(ADMIN_RIGHTS).forEach(
  (k) => (ADMIN_RIGHTS[k].bitmap = BigInt(1) << BigInt(ADMIN_RIGHTS[k].id))
);
export const _hasRight = (role, right) =>
  ADMIN_RIGHTS[right] ? (BigInt(role) & ADMIN_RIGHTS[right].bitmap) > 0 : false;
