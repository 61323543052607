import {
  faCommentDollar,
  faChartBar,
  faPercentage,
  faShareAlt,
  faListAlt,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
export const deliveryMode = [
  { value: "CASH", name: "CASH" },
  { value: "ACCOUNT", name: "ACCOUNT" },
];

export const destination = [
  { value: "ECM", name: "CAMEROON" },
  { value: "EBI", name: "BURUNDI" },
  { value: "EBF", name: "BURKINA FASO" },
  { value: "ECV", name: "CAPE VERDE" },
  { value: "EDF", name: "CENTRAL AFRIQUE" },
  { value: "ECG", name: "CONGO BRAZZAVILLE" },
  { value: "ECD", name: "CONGO DR" },
  { value: "ECI", name: "CÔTE D'IVOIRE" },
  { value: "EGQ", name: "EQUITORIA GUINEA" },
  { value: "EGA", name: "GAMBIA" },
  { value: "EGA", name: "GABON" },
  { value: "EGH", name: "GHANA" },
  { value: "EGW", name: "GUINEA BISSAU" },
  { value: "EGN", name: "GUINEA CONAKRY" },
  { value: "EKE", name: "KENYA" },
  { value: "EMW", name: "MALAWI" },
  { value: "EML", name: "MALI" },
  { value: "EMZ", name: "MOZAMBIQUE" },
  { value: "ENE", name: "NIGER" },
  { value: "ENG", name: "NIGERIA" },
  { value: "ERW", name: "RWANDA" },
  { value: "EST", name: "SAO TOME" },
  { value: "ESN", name: "SENEGAL" },
  { value: "ESL", name: "SIERRA LEONE" },
  { value: "ESD", name: "SOUTH SUDAN" },
  { value: "ETZ", name: "TANZANIA" },
  { value: "ETD", name: "TCHAD" },
  { value: "ETG", name: "TOGO" },
  { value: "EUG", name: "UGANDA" },
  { value: "EZM", name: "ZAMBIA" },
  { value: "EZM", name: "ZIMBABWE" },
];

export const acountTypeOptoins = [
  { value: "A", name: "CURRENT/SAVINGS ACCOUNT", selected: true },
  { value: "X", name: "XPRESS ACCOUNT" },
];

export const amountType = [
  { value: "SEND_AMOUNT", name: "Send Amount" },
  { value: "RECEIVE_AMOUNT", name: "Receive Amount" },
];

export const idType = [
  { value: "CI", name: "NATIONAL ID CARD" },
  { value: "pp", name: "PASSPORT" },
  { value: "CE", name: "VOTERS ID CARD" },
  { value: "PC", name: "DRIVERS LICENCE" },
  { value: "CB", name: "BANK VERIFICATION NUMBER" },
  { value: "CA", name: "NATIONAL HEALTH INSUARANCE" },
  { value: "PR", name: "RESIDENT PERMIT" },
];

export const questions = [
  {
    value: "What was the house number and street name you lived in as a child?",
    name: "What was the house number and street name you lived in as a child?",
  },
  {
    value: "What is your spouse or partner's mother's maiden name?",
    name: "What is your spouse or partner's mother's maiden name?",
  },
  {
    value: "What are the last five digits of your driver's licence number?",
    name: "What are the last five digits of your driver's licence number?",
  },
  {
    value: "What is the middle name of your oldest child?",
    name: "What is the middle name of your oldest child?",
  },
  {
    value: "In what town or city did you meet your spouse/partner?",
    name: "In what town or city did you meet your spouse/partner?",
  },
  {
    value: "In what town or city was your first full time job?",
    name: "In what town or city was your first full time job?",
  },
  {
    value: "What primary school did you attend?",
    name: "What primary school did you attend?",
  },
  {
    value: "What time of the day was your first child born? (hh:mm)",
    name: "What time of the day was your first child born? (hh:mm)",
  },
  {
    value: "What time of the day were you born? (hh:mm)",
    name: "What time of the day were you born? (hh:mm)",
  },
  {
    value: "What were the last four digits of your childhood telephone number?",
    name: "What were the last four digits of your childhood telephone number?",
  },
];

export const reason = [
  { value: "1", name: "Ticket money" },
  { value: "2", name: "Accommodation fees" },
  { value: "3", name: "School fees" },
  { value: "4", name: "Car purchase" },
  { value: "8", name: "Personal Allowance" },
  { value: "9", name: "Payment of Bills" },
  { value: "11", name: "Payment of Mortgage" },
  { value: "12", name: "Payment of Rent" },
  { value: "13", name: "Tourism trip" },
  { value: "14", name: "Professional travel" },
  { value: "15", name: "Travel for medical reasons" },
  { value: "16", name: "Personal travel" },
  { value: "17", name: "Other travels" },
  { value: "18", name: "Salary remuneration" },
  { value: "19", name: "Investment revenue" },
  { value: "20", name: "Family help" },
  { value: "21", name: "Other" },
];

export const termsAndCondition = (t) => [
  {
    title: t("terms.owner"),
    body: t("terms.ownerBody"),
  },
  {
    title: t("terms.user"),
    body: t("terms.userBody"),
  },
  {
    title: t("terms.right"),
    body: t("terms.rightBody"),
  },
];

export const privacy = (t) => [
  {
    title: t("privacy.privacy"),
    body: t("privacy.privacyBody"),
  },
  {
    title: t("privacy.information"),
    body: t("privacy.informationBody"),
  },
  {
    title: t("privacy.cookies"),
    body: t("privacy.cookiesBody"),
  },
];
export const bankDetails = [
  {
    name: "ECOBANK",
    account: "6775016699",
    mode: "	CASH/ONLINE",
  },
  {
    name: "BANK OF KIGALI",
    account: "100112757168",
    mode: " CASH/ONLINE",
  },
  // {
  //   name: "MTN MOBILE MONEY",
  //   account: "0784825387 (paul Barera)",
  //   mode: "SEND",
  // },
  {
    name: "ITEME MERCHANT CODE",
    account: "TH30509183",
    mode: "URUBUTO",
  },
];
export const UTF_NUMBERS = [
  "⓪",
  "①",
  "②",
  "③",
  "④",
  "⑤",
  "⑥",
  "⑦",
  "⑧",
  "⑨",
  "⑩",
  "⑪",
  "⑫",
  "⑬",
  "⑭",
  "⑮",
  "⑯",
  "⑰",
  "⑱",
  "⑲",
  "⑳",
  "㉑",
  "㉒",
  "㉓",
  "㉔",
  "㉕",
  "㉖",
  "㉗",
  "㉘",
  "㉙",
  "㉚",
  "㉛",
  "㉜",
  "㉝",
  "㉞",
  "㉟",
  "㊱",
  "㊲",
  "㊳",
  "㊴",
  "㊵",
  "㊶",
  "㊷",
  "㊸",
  "㊹",
  "㊺",
  "㊻",
  "㊼",
  "㊽",
  "㊾",
];

export const rows = (t) => {
  return {
    inzozi: [
      { label: t("inzozi.Playgame"), link: "buyticket" },
      { label: t("inzozi.Playedgames"), link: "Playedgames" },
      { label: t("inzozi.Results"), link: "Results" },
      { label: t("inzozi.Claim"), link: "claimprize" },
    ],
    report: [
      { name: t("ReqTOPUP"), icon: faCommentDollar, link: "requestCredit" },
      { name: t("TopUpWithMomo"), icon: faMobileAlt, link: "topup" },
      { name: t("PassBook"), icon: faChartBar, link: "passbook" },
      { name: t("Commission"), icon: faPercentage, link: "ViewCommissions" },
      { name: t("TOPUP"), icon: faCommentDollar, link: "CreditRequests" },
      {
        name: t("WalletT"),
        icon: faShareAlt,
        link: "transfer",
      },
    ],
  };
};

export const subAgentMenu = (t) => {
  return [{ name: t("PassBook"), icon: faChartBar, link: "passbook" }];
};
