import {
  FAVORITES,
  PROVIDERS,
  SERVICES,
  TARIFF,
  VENDORS,
} from "../actions/types";
const initialState = {
  list: [],
  tariffs: [],
  favs: [],
  vendors: null,
  providers: null,
};
export const ServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SERVICES:
      return { ...state, list: action.payload };
    case FAVORITES:
      return { ...state, favs: action.payload };
    case TARIFF:
      return { ...state, tariffs: action.payload };
    case VENDORS:
      return { ...state, vendors: action.payload };
    case PROVIDERS:
      return { ...state, providers: action.payload };
    default:
      return state;
  }
};
