import {
  BRANDINGORDERS,
  BRANDINGPRODUCTS,
  BRANDINGPRODUCTSCATEGORIES,
  CLEARPAGE,
} from "../actions/types";

const initialState = {
  totalRows: 0,
  page: 1,
  orderBy: "",
  orderDirection: "",
  orders: [],
  products: [],
  categories: null,
};

const brandingReducer = (state = initialState, action) => {
  let list;
  if (action.payload && action.payload.collection) {
    state.totalRows = action.payload.collection.totalResult;
    state.page = action.payload.collection.pageNumber;
    state.orderBy = action.payload.collection.orderBy;
    state.orderDirection = action.payload.collection.orderDirection;
    list = action.payload.result;
  }
  switch (action.type) {
    case BRANDINGORDERS:
      return {
        ...state,
        orders: list,
      };
    case BRANDINGPRODUCTSCATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case BRANDINGPRODUCTS:
      return {
        ...state,
        products: action.payload.result || action.payload,
      };
    case CLEARPAGE:
      return {
        ...state,
        page: 1,
        products: [],
        orders: [],
      };
    default:
      return state;
  }
};

export default brandingReducer;
