import React from "react";
import { useSelector } from "react-redux";
export default function Spinner() {
  const isLoading = useSelector(
    ({ loadingReducer }) => loadingReducer.isLoading
  );
  const shortSpinner = useSelector(
    ({ loadingReducer }) => loadingReducer.shortSpinner
  );
  return (
    <div
      className={shortSpinner ? "short-overlay" : "overlay"}
      id="preloader"
      style={{ display: isLoading || shortSpinner ? "flex" : "none" }}
    >
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
