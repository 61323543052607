import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { PasswordInput } from "./LoginForm";
import { logout, retypePassword } from "../../_redux/actions/auth";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import iteme_1_no_bg from "../../images/iteme_logo_1_no_bg_no_tag.png";

const RetypePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const loadingReducer = useSelector(({ loadingReducer }) => loadingReducer);
  const auth = useSelector(({ auth }) => auth);
  const onChangePass = (e) => setPassword(e.target.value);
  const submitHandler = () => {
    password &&
      dispatch(retypePassword(password, loadingReducer.originalRequest));
    setPassword("");
  };
  retypePassFailedHandler(dispatch, navigate, auth);
  return (
    <Modal show={loadingReducer.tokenExpired} centered size="sm">
      <div className="itm-modal">
        <img src={iteme_1_no_bg} width="80%" />
        <PasswordForm
          onChangePass={onChangePass}
          password={password}
          onkeyPressHandler={submitHandler}
        />
        <SubmitButton submitHandler={submitHandler} />
      </div>
    </Modal>
  );
};
const PasswordForm = ({ onChangePass, password, onkeyPressHandler }) => {
  const { t } = useTranslation();
  let nameInput;
  useEffect(() => {
    nameInput.focus();
  }, [nameInput]);
  const focusHandler = (input) => {
    nameInput = input;
  };
  const message = useSelector(({ message }) => message.message);
  return (
    <Modal.Body>
      {!message && (
        <h3 className="" style={{ padding: "10px", textAlign: "center" }}>
          {t("Pleaseretypepassword")}
        </h3>
      )}
      {message && <p className="text-center text-danger">{message}</p>}
      <PasswordInput
        password={password}
        onChangePass={onChangePass}
        onkeyPressHandler={onkeyPressHandler}
        focusHandler={focusHandler}
        placeholder="Retype password"
      />
    </Modal.Body>
  );
};

const SubmitButton = ({ submitHandler }) => {
  const { t } = useTranslation();
  return (
    <button
      title={t("fields.Submit")}
      className="itm-hidden"
      onClick={() => submitHandler()}
    >
      Submit
    </button>
  );
};

const retypePassFailedHandler = (dispatch, navigate, auth) => {
  useEffect(() => {
    if (auth.retypePasswordFailed) {
      dispatch(logout());
      navigate("/");
    }
  }, [auth.retypePasswordFailed]);
};
export default RetypePassword;
