import { BANKS, CLEAR, REFINVALID } from "../actions/types";

const initialState = {
  banks: [],
};

const RequestCreditReducer = (state = initialState, action) => {
  switch (action.type) {
    case BANKS:
      return { ...state, banks: action.payload };
    case REFINVALID:
      return { ...state, isInvalid: action.payload };
    case CLEAR:
      return { ...state, isInvalid: undefined };
    default:
      return state;
  }
};

export default RequestCreditReducer;
