import {
  faCheckCircle,
  faShoppingCart,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { deleteBasket, listBaskets } from "../../../_redux/actions/basket";

const Bheader = () => {
  const dispatch = useDispatch();
  const baskets = useSelector(({ BasketReducer }) => BasketReducer);
  useEffect(() => {
    dispatch(listBaskets());
  }, []);
  return (
    <div className="header-button">
      <FontAwesomeIcon icon={faShoppingCart} className="basket-icon" />
      <p className="basket-badge">{baskets.baskets.length}</p>
      <List baskets={baskets.baskets} />
    </div>
  );
};
const List = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="header-submenu">
      {!props.baskets.length && <p className="text-center">No baskets</p>}
      {props.baskets.length > 0 &&
        props.baskets.map((item, i) => {
          return (
            <span className="header-submenu-item" key={i}>
              <span className="header-submenu-icon">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="2x"
                  style={{ paddingRight: "5px" }}
                />{" "}
              </span>
              <span
                className="header-submenu-label"
                onClick={() => navigate(`viewbasket/${item.id}`)}
              >
                {item.name}
                <b className="mx-2" title="transactions">
                  {item.count}
                </b>
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => dispatch(deleteBasket(item.id))}
                  title="delete"
                />
              </span>
            </span>
          );
        })}
    </div>
  );
};


export default Bheader;
