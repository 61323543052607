import Cookies from "js-cookie";
import { wallet } from "../_services/auth.service";
import service from "../_services/service";
import { POSTSUCCESS } from "./actions/types";
import moment from "moment";
export const actionHandler = async (
  url,
  data,
  type,
  dispatch,
  TypeHandler,
  stayWhenError
) => {
  const token = JSON.parse(Cookies.get("user")).token;
  service.service(
    url + token,
    data,
    dispatch,
    null,
    (data_1) => {
      if (data_1) TypeHandler(type, dispatch, data_1, data.amount);
    },
    stayWhenError
  );
};

export const success = (dispatch, message, stayOnC, redirect) => {
  const token = JSON.parse(Cookies.get("user")).token;
  wallet(token, dispatch);
  dispatch({
    type: POSTSUCCESS,
    payload: {
      success: true,
      message: message || "Success",
      redirect: redirect || "/",
      stayOnC: stayOnC && stayOnC.stayOnC,
    },
  });
};

export const filterFun = (start, end) => {
  if (start && end)
    return (
      "&$startDate=" +
      moment(start).format("YYYY-MM-DD") +
      "&$endDate=" +
      moment(end).format("YYYY-MM-DD HH:mm:ss")
    );
  else return "";
};

export const serviceHandler = async (
  type,
  url,
  dispatch,
  method,
  data,
  successMessage,
  callback,
  stayWhenError,
  stayOnC,
  disableLoading,
  noToken,
  errorMessage
) => {
  const token = Cookies.get("user") && JSON.parse(Cookies.get("user")).token;
  const path = noToken ? url : url + token;
  service.service(
    path,
    data,
    dispatch,
    method,
    (data_1) => {
      if (data_1) {
        if (type == POSTSUCCESS) {
          if (data_1.result.failedNumbers)
            successMessage = `SuccessfulCount ${data_1.result.successfulCount} failedCount ${data_1.result.failedCount} 
            failedNumbers ${data_1.result.failedNumbers}`;
          dispatch({
            type: POSTSUCCESS,
            payload: {
              success: true,
              message: successMessage || "Success",
              stayOnC: stayOnC && stayOnC.stayOnC,
              result: data_1.result,
            },
          });
        } else if (type != null)
          dispatch({ type: type, payload: data_1.result });
        callback && callback(data_1);
      }
    },
    stayWhenError || { stayWhenError: stayOnC && stayOnC.stayOnC },
    disableLoading,
    errorMessage
  );
};

export const setPagination = (state, action) => {
  state.totalRows = action.payload.collection.totalResult;
  state.page = action.payload.collection.pageNumber;
  state.orderBy = action.payload.collection.orderBy;
  state.orderDirection = action.payload.collection.orderDirection;
};
