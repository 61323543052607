import { CLEAR, GETAGENTACTIVITY } from "../../actions/types";

const initialState = {
  list: [],
  page: 1,
  totalRows: 0,
};

export const AgentActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETAGENTACTIVITY:
      return {
        ...state,
        list: action.payload.result,
        page: action.payload.collection.pageNumber,
        totalRows: action.payload.collection.totalResult,
      };
    case CLEAR:
      return { ...state, page: 1 };
    default:
      return state;
  }
};
