import React, { useState } from "react";

import ForgotPassWord from "../user/forgotPassWord";
import { useSelector, useDispatch } from "react-redux";
import Login from "../user/Login";
import { CLEAR } from "../../_redux/actions/types";
export default function ShowModal(props) {
  const dispatch = useDispatch();
  const [fp, setFp] = useState(false);
  const forgotPass = ({ close }) => {
    if (close) setFp(false);
    else setFp(!fp);
    dispatch({ type: CLEAR });
  };
  const passWordReducer = useSelector(({ passWordReducer }) => passWordReducer);
  let body = <Login {...props} fphandler={forgotPass} />;
  if (fp && !passWordReducer.passWordChanged)
    body = <ForgotPassWord {...props} fphandler={forgotPass} />;
  if (passWordReducer.otp)
    body = <ForgotPassWord {...props} fphandler={forgotPass} isOtp={true} />;
  if (passWordReducer.isNew)
    body = (
      <ForgotPassWord {...props} fphandler={forgotPass} isNewPass={true} />
    );
  return body;
}
