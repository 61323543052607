import React from "react";
import SectionHeader from "./SectionHeader";
import ServicesRow from "./ServiceRow";
import { useTranslation } from "react-i18next";
import AboutRow from "./AboutRow";
import {
  faDiceTwo,
  faHandHoldingUsd,
  faHands,
  faMoneyCheck,
  faMoneyCheckAlt,
  faShareAltSquare,
} from "@fortawesome/free-solid-svg-icons";
const Categories = ({ categorie }) => {
  const { t } = useTranslation();
  categorie.push(
    {
      name: t("services.categories2_title"),
      services: [
        { name: t("services.categories2_services.s1"), icon: faMoneyCheckAlt },
        { name: t("services.categories2_services.s2"), icon: faMoneyCheck },
        { name: t("services.categories2_services.s3"), icon: faShareAltSquare },
        { name: t("services.categories2_services.s4"), icon: faHands },
        { name: "", icon: "" },
      ],
    },
    {
      name: t("services.categories3_title"),
      services: [
        { name: t("services.categories3_services.s1"), icon: faHandHoldingUsd },
        { name: t("services.categories3_services.s2"), icon: faDiceTwo },
        { name: "", icon: "" },
        { name: "", icon: "" },
        { name: "", icon: "" },
      ],
    }
  );
  return <Row aboutContent={categorie} title={t("services.title")} />;
};

export const Row = ({ aboutContent, title, terms }) => {
  const { t } = useTranslation();
  let termContent = [
    {
      title: t("terms.title"),
      body: t("terms.body"),
    },
    {
      title: t("terms.owner"),
      body: t("terms.ownerBody"),
    },
    {
      title: t("terms.user"),
      body: t("terms.userBody"),
    },
    {
      title: t("terms.right"),
      body: t("terms.rightBody"),
    },
  ];
  const content = terms ? termContent : aboutContent;
  return <SectionRow content={content} title={title} />;
};

const SectionRow = ({ title, content }) => {
  return (
    <section  className="home-section section">
      <div className="">
        {title ? <SectionHeader title={title} /> : ""}
        {content.map((cat, i) => {
          return (
            <div key={i}>
              {cat.services ? (
                <ServicesRow title={cat.name} services={cat.services} key={i} />
              ) : (
                <AboutRow content={cat.body} title={cat.title} key={i} />
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
};
export default Categories;
