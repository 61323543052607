import { serviceHandler } from "../common";
import {
  ADDTOBASKET,
  GETBASKET,
  LISTBASKETS,
  POSTSUCCESS,
  REMOVEFROMBASKET,
  SUBMITBASKET,
} from "./types";

export const listBaskets = () => async (dispatch) => {
  serviceHandler(LISTBASKETS, "/transaction/listBaskets?authtoken=", dispatch);
};

export const getBasket = (basket_id) => async (dispatch) => {
  serviceHandler(
    GETBASKET,
    "/transaction/getBasket?authtoken=",
    dispatch,
    "post",
    { basket_id }
  );
};

export const addToBasket = (data) => async (dispatch) => {
  data.payload.customer_phone = data.customer_mobile;
  serviceHandler(
    ADDTOBASKET,
    "/transaction/addToBasket?authtoken=",
    dispatch,
    "post",
    data,
    null,
    () => dispatch(listBaskets())
  );
};

export const removeFromBasket = (basket_id, item_id) => async (dispatch) => {
  serviceHandler(
    REMOVEFROMBASKET,
    "/transaction/removeFromBasket?authtoken=",
    dispatch,
    "post",
    { basket_id, item_id },
    null,
    () => {
      dispatch(getBasket(basket_id));
      dispatch(listBaskets());
    }
  );
};

export const deleteBasket = (basket_id) => async (dispatch) => {
  serviceHandler(
    "DELETEBASKET",
    "/transaction/deleteBasket?authtoken=",
    dispatch,
    "post",
    { basket_id },
    null,
    () => dispatch(listBaskets())
  );
};
// export const submitBasket = (basket_id) => async (dispatch) => {
//   serviceHandler(
//     //SUBMITBASKET,
//     null,
//     "/transaction/submitBasket?authtoken=",
//     dispatch,
//     "post",
//     { basket_id }
//   );
// };
