import { type } from "jquery";
import service from "../../../_services/service";
import { filterFun, success } from "../../common";
import { kycList } from "./user";
import { listCreditRequest } from "./wallet";
import { AGENTCLAIMS } from "../types";
import Cookies from "js-cookie";

export const callBack = (dispatch, data_1, message, list) => {
  if (data_1 && data_1.isSuccessful) {
    success(dispatch, message, { stayOnC: true });
    dispatch(
      list({
        pageNo: 1,
        pageSize: 10,
        orderBy: "at_modified",
        orderDirection: "DESC",
      })
    );
  }
};
// reject or approve
export const rejectOrApproveRequest =
  (data, { url, message }) =>
  async (dispatch) => {
   // const token = JSON.parse(localStorage.getItem("user")).token;
 const token = JSON.parse(Cookies.get("user")).token
    service.service(
      url + token,
      data,
      dispatch,
      "post",
      (data_1) => callBack(dispatch, data_1, message, listCreditRequest),
      { stayWhenError: true }
    );
  };

export const rejectOrApproveKyc =
  (data, { url, message }) =>
  async (dispatch) => {
  
  const token = JSON.parse(Cookies.get("user")).token
    service.service(
      url + token,
      data,
      dispatch,
      "post",
      (data_1) => callBack(dispatch, data_1, message, kycList),
      { stayWhenError: true }
    );
  };
// getting list
export const getList = (dispatch, url, type, data, searchBy) => {
const token = JSON.parse(Cookies.get("user")).token
  const search = getSearchFilter(searchBy);
  service.service(url + token, data || search, dispatch, "", (data) => {
    if (data && data.result && data.isSuccessful)
      dispatch({
        type: type,
        payload: data,
      });
  });
};

export const handler = (
  url,
  dispatch,
  type,
  body,
  searchBy,
  method,
  stayWhenError,
  disableLoading
) => {

const token = JSON.parse(Cookies.get("user")).token
  const search = getSearchFilter(searchBy);
  service.service(
    url + token,
    body || search,
    dispatch,
    method || "post",
    (data) => {
      if (data && data.isSuccessful) dispatch({ type: type, payload: data });
    },
    stayWhenError,
    disableLoading
  );
};

export const getSearchFilter = (searchBy) => {
  if (typeof searchBy == "object") return searchBy;
  else if (typeof searchBy == "string" && searchBy != "")
    return { $search: { $all: searchBy } };
  else return;
};
export const listHandler = (
  { pageNo, pageSize, orderBy, searchBy, start, end, orderDirection },
  url,
  data,
  dispatch,
  type
) => {
  const filter = filterFun(start, end);
  handler(
    url +
      "?$pagesize=" +
      pageSize +
      "&$pagenumber=" +
      pageNo +
      filter +
      "&$orderby=" +
      orderBy +
      "&$orderdir=" +
      orderDirection +
      "&authtoken=",
    dispatch,
    type || AGENTCLAIMS,
    data,
    searchBy
  );
};

export const getImageUrl = (file, dispatch, callBack) => {

const token = JSON.parse(Cookies.get("user")).token
  const extension = file.name.split(".").pop();
  service.service(
    `/admin/getPresignedPutURL?extension=${extension}&authtoken=${token}`,
    "",
    dispatch,
    "get",
    callBack
  );
};
