import {
  GAMESLIST,
  GETPLAYEDGAMESUCCESS,
  CHECKCLAIMSUCCESS,
  GETRESULTSUCCESS,
  PLAYGAMESUCCESS,
  HIDEMODAL,
} from "../actions/types";

const initialState = {
  games: JSON.parse(localStorage.getItem("games")),
  playedGames: [],
  totalRows: 0,
  page: 1,
  claimInfo: {},
  results: [],
  orderBy: "",
  orderDirection: "",
};

const inzoziReducer = (state = initialState, action) => {
  switch (action.type) {
    case GAMESLIST:
      return { ...state, games: action.payload };
    case GETPLAYEDGAMESUCCESS:
      return {
        ...state,
        playedGames: action.payload.result,
        totalRows: action.payload.collection.totalResult,
        page: action.payload.collection.pageNumber,
        orderBy: action.payload.collection.orderBy,
        orderDirection: action.payload.collection.orderDirection,
      };
    case CHECKCLAIMSUCCESS:
      return { ...state, claimInfo: action.payload };
    case GETRESULTSUCCESS:
      return { ...state, results: action.payload.result.ResultInfo };
    case PLAYGAMESUCCESS:
      return playSuccess(state, action);
    case HIDEMODAL:
      return { ...state, showModal: false };
    default:
      return state;
  }
};
const playSuccess = (state, action) => {
  return {
    ...state,
    showModal: true,
    message: "Ticketsuccessfullybought",
    transactionId: action.payload.data_1.result.transaction_id,
    redirect: action.payload.redirect,
  };
};
export default inzoziReducer;
