import React from "react";
import Report from "./Report";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions } from "../../../_redux/actions/report";
import { Money } from "../../_common/Table";
import { getInfo, MoneyDisplay, myHook } from "../../_common/common";
import { printReceipt } from "../../../_redux/actions/printReceipt";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { _isAgent } from "../../../_constant/user_rights";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faFileAlt,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
const Passbook = (props) => {
  const passbook = useSelector(({ reportReducer }) => reportReducer);
  const profile = useSelector(({ walletReducer }) => walletReducer).profile;
  const { t } = useTranslation();
  const obj = myHook({
    page: props.page || passbook.page,
    action: props.action || getTransactions,
    id: props.id,
  });
  return (
    <>
      <Report
        title={props.title || t("PassBook").toUpperCase()}
        data={passBookList(props.data || passbook.passbook)}
        rows={props.totalRows || passbook.totalRows}
        page={props.page || passbook.page}
        isPassBook={true}
        downloadList={downloadList(props.data || passbook.passbook)}
        profile={profile}
        statistic={passbook.passbookInfo}
        body={
          <PassBookBody
            data={passBookList(props.data || passbook.passbook)}
            backBtn={props.backBtn}
            profile={profile}
          />
        }
        backBtn={props.backBtn}
        {...obj}
      />
    </>
  );
};
const downloadList = (data) => {
  let arr = [];
  const list = passBookList(data);
  list.forEach((item) => {
    arr.push({
      date: item.date,
      id: item.id,
      status: item.status,
      type: item.type,
      amount: item.amount,
      wallet: item.wallet,
      transactionID: item.transactionID,
    });
  });
  return arr;
};
export const passBookList = (passbook) => {
  const passbookArr = [];
  passbook.forEach((item) => {
    item.is_commission ? (item.type = "Commissions") : "";
    item.is_success ? (item.status = "Success") : "Fail";
    if (item.transaction != null) {
      let { itemrefund, itemstatus } = status(item.transaction.status);
      item.status = itemstatus;
      item.refound = itemrefund;
      item.type = item.transaction.provider.name;
      item.transactionID = item.transaction.txn_id;
    } else if (item.paymentrequest != null) {
      item.paymentrequest.status == 1 ? (item.status = "Approved") : "";
      item.type = "Payment Request";
    }
    item.wallet = parseFloat(item.closing_balance).toFixed(2);
    item.amount = parseFloat(item.balance).toFixed(2) + " RWF ";
    item.date = new Date(item.at_modified).toLocaleString("en-GB");
    let { printbtn } = handlerPrint(item);
    item.print = printbtn;
    passbookArr.push(item);
  });
  return passbookArr;
};
const handlerPrint = (item) => {
  let printbtn;
  let transactionId;
  if (item.transaction) {
    transactionId = getTransactionId(item);

    if (item.transaction.type.wallet_deduction_type == "CASH_IN") {
      printbtn = true;
    } else printbtn = false;
  }

  return { transactionId, printbtn };
};
const getTransactionId = (item) => {
  let respone;
  if (typeof item.transaction.transaction_request_json == "string")
    respone = JSON.parse(item.transaction.transaction_request_json);
  else respone = item.transaction.transaction_request_json || {};
  let transactionID = respone.header && respone.header.requestId;

  return transactionID;
};
export const status = (status) => {
  let itemstatus;
  let statusicon;
  let color;
  switch (status) {
    case "S":
      itemstatus = "Success";
      statusicon = faCheckCircle;
      color = "green";
      break;
    case "F":
      itemstatus = "Failed";
      statusicon = faTimesCircle;
      color = "red";
      break;
    case "P":
      itemstatus = "Pending";
      statusicon = faSpinner;
      color = "black";
      break;
  }
  return { itemstatus, statusicon, color };
};

const PassBookBody = ({ data, backBtn, profile }) => {
  const user = useSelector(({ walletReducer }) => walletReducer).profile;
  const { t } = useTranslation();
  const navigate = useNavigate();
  return data.map((item, i) => {
    const info = getInfo(item);
    return (
      <tr
        key={i}
        onClick={() => {
          _showDetails(item, navigate);
        }}
        className="sort-btn"
      >
        <td data-label={t("Date")}>{item.date}</td>
        <td data-label={item.transactionID && t("Txn ID")}>
          {item.transactionID || item.paymentrequest?.ref_no}
        </td>
        <td data-label={t("Status")}>{item.status}</td>
        <td data-label={t("Service")}>{item.type}</td>
        <td data-label={info && t("Info")}>{info}</td>
        <Money
          amount={item.amount}
          isPassBook={true}
          label={t("fields.Amount")}
        />
        <Money amount={item.wallet} label={t("Wallet")} />
        {profile && _isAgent(profile.user_rights, "IS_SUPERAGENT") && (
          <td data-label={t("DoneBy")}>
            {item.agent
              ? item.agent.first_name + " " + item.agent.last_name
              : "  "}
          </td>
        )}

        <td style={{ textAlign: "right" }}>
          {item.transaction && user.first_name == item.agent.first_name && (
            <PrintBtn backBtn={backBtn} item={item} />
          )}
        </td>
      </tr>
    );
  });
};

const _showDetails = (data, navigate) => {
  switch (data.type) {
    case "Commissions":
      navigate(`/commissionDetails/${data.id}`);
      break;
    case "REFUND":
      navigate(`/agentToAgentDetails/${data.id}`);
      break;
    case "AGENT TO AGENT SEND":
      navigate(`/agentToAgentDetails/${data.id}`);
      break;
    case "AGENT TO AGENT RECEIVE":
      navigate(`/agentToAgentDetails/${data.id}`);
      break;
    case "Payment Request":
      navigate(`/creditRequestDetails/${data.id}`);
      break;
    case "UNDO CREDIT REQUEST":
      navigate(`/creditRequestDetails/${data.id}`);
      break;
    default:
      data.transaction &&
        navigate(`/passbookDetails/${data.id}`);
      break;
  }
};

const PrintBtn = ({ backBtn, item }) => {
  const dispatch = useDispatch();
  return (
    <>
      {!backBtn && item.print && item.transaction.status == "S" && (
        <FontAwesomeIcon
          icon={faFileAlt}
          title="print receipt"
          className="tdButton mx-2"
          onClick={() =>
            printHandler(item.transactionID, dispatch, i18n.language)
          }
        />
      )}
    </>
  );
};
const printHandler = (transactionId, dispatch, lang) => {
  dispatch(printReceipt(transactionId, lang));
};

export const PassBooKInfo = ({ statistic }) => {
  const { t } = useTranslation();
  return (
    <div className="child" style={{ paddingLeft: "3.4rem" }}>
      <span>
        {t("Trasactions")} {statistic.transactions}
      </span>
      {statistic.subagentsTotal ? (
        <p>
          sub Agent total <MoneyDisplay amount={statistic.subagentsTotal} />
          <br></br>
          <span>
            {t("SubAgentTrasactions")} {statistic.subagentsTransactions}
          </span>
        </p>
      ) : (
        ""
      )}
    </div>
  );
};
export default Passbook;
