import { useTranslation } from "react-i18next";

export const checkPasswords = (password, password2) => {
  const { t } = useTranslation();
  let ok = true;
  let error = "";
  if (!password || !password2) ok = false;
  else if (password !== password2) {
    ok = false;
    error = t("Passwordsnotmuch");
  } else if (password.length < 8) {
    ok = false;
    error = "Password must have at least 8 characters!";
  } else if (/[A-Z]/.test(password) === false) {
    ok = false;
    error = "Password must contain at least one upper case letter!";
  } else if (/[a-z]/.test(password) === false) {
    ok = false;
    error = "Password must contain at least one lower case letter!";
  } else if (/\d/.test(password) === false) {
    ok = false;
    error = "Password must contain at least one digit!";
  } else if (/[^\w\s]/.test(password) === false) {
    ok = false;
    error = "Password must contain at least one special character!";
  } else if (/\s/.test(password) === true) {
    ok = false;
    error = "Password must not contain spaces!";
  }
  return { ok, error };
};
