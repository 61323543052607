import Cookies from "js-cookie";
import service from "../../../_services/service";
import { filterFun, serviceHandler, success } from "../../common";
import {
  GETAGENTSUCCESS,
  GETPRESIGNEDPUTURL,
  ISLOADING,
  GETKYCSUCCESS,
  USERDETAILS,
  KYCCOUNT,
  ISMOBILE,
  GETADMINUSER,
  GETTSM,
  GETAGENTACTIVITY,
  POSTSUCCESS,
  AGENTAPPLICATION,
} from "../types";
import { getImageUrl, getList, getSearchFilter, handler } from "./common";
/* users management actions */

// list users with no admin rigths
export const getUsers =
  ({ pageNo, pageSize, orderBy, searchBy, orderDirection }) =>
  async (dispatch) => {
    const url =
      "/users/list?$pagesize=" +
      pageSize +
      "&$pagenumber=" +
      pageNo +
      "&$orderby=" +
      orderBy +
      "&$orderdir=" +
      orderDirection +
      "&authtoken=";
    getList(dispatch, url, GETAGENTSUCCESS, "", searchBy);
  };
// list users with admin rights
export const getAdminUsers =
  ({ pageNo, pageSize, orderBy, searchBy, orderDirection }) =>
  async (dispatch) => {
    const url = pageNo
      ? "/users/listAdminUsers?$pagesize=" +
        pageSize +
        "&$pagenumber=" +
        pageNo +
        "&$orderby=" +
        orderBy +
        "&$orderdir=" +
        orderDirection +
        "&authtoken="
      : `/users/listAdminUsers?$pagesize=${pageSize || 10}&authtoken=`;
    getList(dispatch, url, GETADMINUSER, "", searchBy);
  };

// list users with TSM rigths
export const getTSMs = () => async (dispatch) => {
  const url = "/users/listTSMs?authtoken=";
  getList(dispatch, url, GETTSM);
};
export const getPresignedPutURL =
  ({ file, type }) =>
  async (dispatch) => {
    getImageUrl(file, dispatch, (data) => {
      if (data.result && data.isSuccessful)
        dispatch({
          type: GETPRESIGNEDPUTURL,
          payload: { file: file, type: type, result: data },
        });
    });
  };
// adding user or sub user
export const addUser = (data, url, message) => async (dispatch) => {
  let kycData = [];
  data.files.forEach((element) => {
    kycData.push({
      type: element.type,
      filename: element.result?.result?.filename,
    });
  });
  data.user.kycdata = kycData;

  const token = JSON.parse(Cookies.get("user")).token;
  service.service(
    url ? url + token : `/users/create?authtoken=${token}`,
    data.user,
    dispatch,
    "post",
    async (data_1) => {
      if (data_1 && data_1.isSuccessful) {
        await upload(data.files, dispatch);
        success(dispatch, message || "User Created Successfully", "admin");
      }
    },
    { stayWhenError: true }
  );
};
export const upload = async (files, dispatch) => {
  dispatch({ type: ISLOADING, isLoading: true });
  for (let i = 0; i < files.length; i++) {
    var file = files[i];
    if (file.result?.result)
      await uploadFile(file.file, file.result?.result?.url);
    else await uploadFile(file.file, file.result?.url);
  }
  dispatch({ type: ISLOADING, isLoading: false });
};

const uploadFile = async (file, url) => {
  try {
    const res = await fetch(url, {
      method: "PUT",
      body: file,
    });
    return res;
  } catch (error) {
    // console.log(error);
  }
};

// activate or deactivate users
export const activateDeactivateUser = (id, url) => (dispatch) => {
  const token = JSON.parse(Cookies.get("user")).token;
  const message = url.includes("deactivate")
    ? "User deactivate Successfully"
    : "User Activated Successfully";
  service.service(
    url + token,
    { userid: id },
    dispatch,
    "post",
    async (data_1) => {
      if (data_1 && data_1.isSuccessful) {
        success(dispatch, message, "admin");
      }
    }
  );
};

// list kyc
export const kycList = (pagination) => async (dispatch) => {
  const token = JSON.parse(Cookies.get("user")).token;
  const url =
    "/users/listKYC?$pagesize=" +
    pagination.pageSize +
    "&$pagenumber=" +
    pagination.pageNo +
    "&$orderby=" +
    pagination.orderBy +
    "&$orderdir=" +
    pagination.orderDirection +
    "&authtoken=" +
    token;
  service.service(
    url,
    getSearchFilter(pagination.searchBy),
    dispatch,
    "post",
    (data) => {
      if (data.result && data.isSuccessful)
        dispatch({ type: GETKYCSUCCESS, payload: data });
    }
  );
};

//update user or sub user
export const update = (data, id, url) => async (dispatch) => {
  const token = JSON.parse(Cookies.get("user")).token;
  data.userid = id;
  service.service(
    url ? url + token : `/users/update?authtoken=${token}`,
    data,
    dispatch,
    "post",
    async (data_1) => {
      if (data_1 && data_1.isSuccessful) {
        success(dispatch, "User Updated Successfully", "admin");
      }
    }
  );
};
// getting one user details
export const details = (data) => async (dispatch) => {
  const token = JSON.parse(Cookies.get("user")).token;
  const url = "/users/getDetails?authtoken=" + token;
  service.service(url, data, dispatch, "post", (data) => {
    if (data.result && data.isSuccessful)
      dispatch({ type: USERDETAILS, payload: data });
  });
};
// managing admin rights
export const setRights = (data) => async (dispatch) => {
  const token = JSON.parse(Cookies.get("user")).token;
  const url = "/users/setAdminRights?authtoken=" + token;
  service.service(
    url,
    data,
    dispatch,
    "post",
    (data) => {
      if (data.result && data.isSuccessful)
        success(dispatch, "User rights set Successfully", { stayOnC: true });
    },
    { stayWhenError: true }
  );
};
// managing agent rights
export const setAgentRights = (data) => async (dispatch) => {
  const token = JSON.parse(Cookies.get("user")).token;
  const url = "/users/setUserRights?authtoken=" + token;
  service.service(url, data, dispatch, "post", (data) => {
    if (data.result && data.isSuccessful)
      success(dispatch, "User rights set Successfully", { stayOnC: true });
  });
};
export const counterKyc = () => async (dispatch) => {
  handler("/users/countKYC?authtoken=", dispatch, KYCCOUNT);
};

export const mobileNumberAllowed = (mobileNo) => async (dispatch) => {
  handler("/users/mobileNumberAllowed?authtoken=", dispatch, ISMOBILE, {
    mobile_no: mobileNo,
  });
};
export const agentActivity = (data) => async (dispatch) => {
  const { pageSize, pageNo, orderBy, orderDirection, searchBy, start, end } =
    data;
  const filter = start && end && filterFun(start, end);
  const url = filter
    ? "/users/listActivity?$pagesize=" +
      pageSize +
      "&$pagenumber=" +
      pageNo +
      filter +
      "&$orderby=" +
      orderBy +
      "&$orderdir=" +
      orderDirection +
      "&authtoken="
    : "/users/listActivity?$pagesize=" +
      pageSize +
      "&$pagenumber=" +
      pageNo +
      "&$orderby=" +
      orderBy +
      "&$orderdir=" +
      orderDirection +
      "&authtoken=";

  getList(dispatch, url, GETAGENTACTIVITY, "", searchBy);
};

export const resendWelcomEmail = (user_id) => async (dispatch) =>
  serviceHandler(
    POSTSUCCESS,
    "/users/resendWelcomeEmail?authtoken=",
    dispatch,
    "post",
    { user_id },
    "Email sent.",
    null,
    { stayWhenError: true }
  );
export const replaceImage = (options) => async (dispatch) => {
  await upload(options.file, dispatch);
  serviceHandler(
    POSTSUCCESS,
    "/users/replaceKYCDocument?authtoken=",
    dispatch,
    "post",
    {
      kyc_id: options.kyc_id,
      filename: options.file && options.file[0].result.result.filename,
    },
    null,
    null,
    {
      stayWhenError: true,
    },
    { stayOnC: true }
  );
};

export const addNewImage = (options) => async (dispatch) => {
  await upload(options.file, dispatch);
  serviceHandler(
    POSTSUCCESS,
    "/users/addKYCDocument?authtoken=",
    dispatch,
    "post",
    {
      userid: options.id,
      filename: options.file && options.file[0].result.result.filename,
      type: options.type,
    },
    null,
    null,
    {
      stayWhenError: true,
    },
    { stayOnC: true }
  );
};

export const listAgentApplications =
  ({ pageNo, pageSize, orderBy, searchBy, orderDirection }) =>
  async (dispatch) => {
    const url =
      "/users/listAgentApplications?$pagesize=" +
      pageSize +
      "&$pagenumber=" +
      pageNo +
      "&$orderby=" +
      orderBy +
      "&$orderdir=" +
      orderDirection +
      "&authtoken=";
    getList(dispatch, url, AGENTAPPLICATION, "", searchBy);
  };

export const completeRegistration =
  (data, url, message) => async (dispatch) => {
    let kycData = [];
    data.files.forEach((element) => {
      kycData.push({
        type: element.type,
        filename: element.result?.result?.filename,
      });
    });
    data.user.kycdata = kycData;

    const token = JSON.parse(Cookies.get("user")).token;
    service.service(
      url ? url + token : `/users/updateByTSM?authtoken=${token}`,
      data.user,
      dispatch,
      "post",
      async (data_1) => {
        if (data_1 && data_1.isSuccessful) {
          await upload(data.files, dispatch);
          success(dispatch, message || "Information saved Successfully");
        }
      },
      { stayWhenError: true }
    );
  };
