import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ItemeModal } from "../_common/Modal";
import { Footer, ItemeTabForm } from "../_common/Forms";
import { termsAndCondition, privacy } from "../../_constant/formData";
const Terms = () => {
  const terms = useSelector(({ loadingReducer }) => loadingReducer).terms;
  return (
    <ItemeModal
      size="lg"
      show={terms}
      body={<ItemeModalBody />}
      footer={<Footer isIteme={true} />}
      isIteme={true}
    />
  );
};

const ItemeModalBody = () => {
  const { t } = useTranslation();
  const tabs = [
    { title: t("header.terms"), eventKey: "home", data: termsAndCondition(t) },
    { title: t("header.privacy"), eventKey: "profile", data: privacy(t) },
  ];
  return <ItemeTabForm tabs={tabs} isIteme={true} />;
};
// to be used in tab
export const ItemeTerms = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div>{t("terms.body")}</div>
      {props.data.map((item, i) => {
        return (
          <div key={i}>
            <div className="intro-header">
            <br></br>
              <h3>{item.title}</h3>
              <br></br>
            </div>
            <div>{item.body}</div>
          </div>
        )
      })}
    </>
  );
};

export default Terms;
