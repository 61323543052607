import { relativeTimeRounding } from "moment";
import { AGENTNOTFOUND, CLEAR, AGENTFOUND, MOMOFEE } from "../actions/types";

const initialState = {
  agent: null,
  message: "",
  momoFee: 0,
};

export const TransferReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGENTNOTFOUND:
      return { ...state, agent: null, message: action.payload };
    case AGENTFOUND:
      return { ...state, message: undefined, agent: action.payload };
    case MOMOFEE:
      return { ...state, momoFee: action.payload };
    case CLEAR:
      return { ...state, message: undefined };
    default:
      return state;
  }
};
