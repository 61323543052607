export const USER_RIGHTS = {
  TRANSACT: {
    id: 0,
    description: "User can make transactions on the platform as an agent",
  },
  IS_SUPERAGENT: {
    id: 1,
    description:
      "User can perform system admin tasks or experimental admin tasks.",
  },
  SANDBOX_TEST_1: {
    id: 2,
    description: "Agents selected for Sandbox testing pahse 1.",
  },
  SANDBOX_TEST_2: {
    id: 3,
    description: "Agents selected for Sandbox testing pahse 2.",
  },
  SANDBOX_TEST_3: {
    id: 4,
    description: "Agents selected for Sandbox testing pahse 3.",
  },
  SANDBOX_TEST_4: {
    id: 5,
    description: "Agents selected for Sandbox testing pahse 4.",
  },
  DO_4G_ACTIVATIONS: {
    id: 6,
    description: "Agent is allowed to activate 4G customers",
  },
};
Object.keys(USER_RIGHTS).forEach(
  (k) => (USER_RIGHTS[k].bitmap = BigInt(1) << BigInt(USER_RIGHTS[k].id))
);
export const _isAgent = (role, right) =>
  USER_RIGHTS[right] ? (BigInt(role) & USER_RIGHTS[right].bitmap) > 0 : false;

