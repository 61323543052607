import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import checked from "../../images/checked.png";
import cancel from "../../images/cancel.png";
import { CLEAR, HIDEMODAL } from "../../_redux/actions/types";
import { useLocation, useNavigate } from "react-router";
import { printReceipt } from "../../_redux/actions/printReceipt";
import { logout } from "../../_redux/actions/auth";
const Notification = () => {
  const globaReducer = useSelector(({ loadingReducer }) => loadingReducer);
  let message;
  if (globaReducer.message.includes("network timeout"))
    message = "Network timeout! try again later.";
  return (
    <Modal
      show={globaReducer.error ? globaReducer.error : globaReducer.success}
      centered
    >
      <img
        src={globaReducer.success ? checked : cancel}
        className="notificationIcon"
      />
      <ModalBody globaReducer={globaReducer} message={message} />
    </Modal>
  );
};
const goHome = ({ navigate, globaReducer }) => {
  if (!globaReducer.stayWhenError) {
    navigate(location.pathname.includes("admin") ? "admin" : "/");
  }
};
const ModalBody = ({ globaReducer, message }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const onClickHandler = (close) => {
    if (globaReducer.receipt && !close)
      dispatch(printReceipt(globaReducer.transactionId));
    if (globaReducer.passwordChanged) dispatch(logout());
    dispatch({ type: HIDEMODAL });
    goHome({
      navigate,
      globaReducer,
      location,
    });
  };
  return (
    <ModalBodyUI
      globaReducer={globaReducer}
      message={message}
      onClickHandler={onClickHandler}
    />
  );
};
const ModalBodyUI = (props) => {
  const dispatch = useDispatch();
  return (
    <Modal.Body>
      <h3 style={{ textAlign: "center", margin: "50px" }}>
        {props.message || props.globaReducer.message}
      </h3>
      {props.globaReducer.receipt ? (
        <button
          className="iteme-button"
          onClick={() => {
            props.onClickHandler(true);
            dispatch({ type: CLEAR });
          }}
        >
          close
        </button>
      ) : (
        ""
      )}
      <button
        title="close"
        className="iteme-button"
        style={{ float: "right" }}
        onClick={() => {
          props.onClickHandler(!props.globaReducer.receipt);
          !props.globaReducer.stayWhenError && dispatch({ type: CLEAR });
        }}
      >
        {props.globaReducer.receipt ? "Print Receipt" : "Close"}
      </button>
    </Modal.Body>
  );
};
export default Notification;
