import { data } from "jquery";
import service from "../../_services/service";
import { filterFun, serviceHandler } from "../common";
import { getSearchFilter, handler } from "./admin/common";
import {
  PASSBOOK,
  COMMISSION,
  CREDITREQUEST,
  COMMISSIONSUM,
  PASSBOOKDETAILS,
  PASSBOOKSTATISTICS,
  EXPORTDATA,
} from "./types";
import Cookies from "js-cookie";

export const getTransactions =
  ({ pageNo, start, end, pageSize, orderBy, orderDirection, searchBy }) =>
  async (dispatch) => {
    const token = JSON.parse(Cookies.get("user")).token;
    const search = getSearchFilter(searchBy);
    const filter = filterFun(start, end);
    const url =
      "/passbook/list?$pagesize=" +
      pageSize +
      "&$pagenumber=" +
      pageNo +
      filter +
      "&$orderby=" +
      orderBy +
      "&$orderdir=" +
      orderDirection +
      "&authtoken=" +
      token;
    service.service(url, search, dispatch, "post", (data) => {
      if (data && data.isSuccessful)
        dispatch({ type: PASSBOOK, payload: data });
    });
    getPassbookStatistics(filter, dispatch, data);
  };
export const getRequest =
  (page, sub_url, start, end, size, orderBy, searchBy, orderDirection) =>
  async (dispatch) => {
    const token = JSON.parse(Cookies.get("user")).token;
    const search = getSearchFilter(searchBy);
    const filter = filterFun(start, end);
    const url =
      sub_url +
      `/list?$pagesize=${size}&$pagenumber=` +
      page +
      filter +
      "&$orderby=" +
      orderBy +
      "&$orderdir=" +
      orderDirection +
      "&authtoken=" +
      token;
    service.service(url, search, dispatch, "post", (data) => {
      if (data && data.isSuccessful) getRequestSuccess(data, sub_url, dispatch);
    });
  };

const getRequestSuccess = (data, sub_url, dispatch) => {
  let type;
  if (sub_url == "/creditrequest") type = CREDITREQUEST;
  else {
    type = COMMISSION;
    sumOfCommission(dispatch);
  }
  dispatch({
    type: type,
    payload: data,
  });
};

const sumOfCommission = async (dispatch) => {
  const token = JSON.parse(Cookies.get("user")).token;
  const url = "/commission/sumUnsettled?authtoken=" + token;
  service.service(url, "", dispatch, "get", (data) => {
    if (data && data.isSuccessful) {
      dispatch({
        type: COMMISSIONSUM,
        payload: data.result,
      });
    }
  });
};

const getPassbookStatistics = async (filter, dispatch, data) => {
  serviceHandler(
    PASSBOOKSTATISTICS,
    `/passbook/getPassbookStatistics?${filter}&authtoken=`,
    dispatch,
    "post",
    data
  );
};
export const getPassbookById = (id) => async (dispatch) => {
  serviceHandler(
    PASSBOOKDETAILS,
    `/passbook/details?authtoken=`,
    dispatch,
    "post",
    {
      id,
    }
  );
};

export const listBonus =
  ({ pageNo, start, end, pageSize, orderBy, orderDirection, searchBy }) =>
  async (dispatch) => {
    const token = JSON.parse(Cookies.get("user")).token;
    const search = getSearchFilter(searchBy);
    const filter = filterFun(start, end);
    const url =
      "/passbook/listBonus?$pagesize=" +
      pageSize +
      "&$pagenumber=" +
      pageNo +
      filter +
      "&$orderby=" +
      orderBy +
      "&$orderdir=" +
      orderDirection +
      "&authtoken=" +
      token;
    service.service(url, search, dispatch, "post", (data) => {
      if (data && data.isSuccessful)
        dispatch({ type: PASSBOOK, payload: data });
    });
  };

export const listUserBonus =
  ({ pageNo, pageSize, orderBy, searchBy, id, start, end, orderDirection }) =>
  async (dispatch) => {
    const filter = filterFun(start, end);
    handler(
      `/passbook/userBonus?$pagesize=${pageSize}&$pagenumber=${pageNo}${filter}&$orderby=${orderBy}&$orderdir=${orderDirection}&authtoken=`,
      dispatch,
      PASSBOOK,
      { userid: id },
      searchBy
    );
  };

export const getExportData =
  ({ path, start, end, noPagination, orderDirection }) =>
  async (dispatch) => {
    const token = JSON.parse(Cookies.get("user")).token;
    const filter = filterFun(start, end);
    const url =
      path ||
      "/passbook/list?" +
        "$orderdir=" +
        orderDirection +
        filter +
        "&authtoken=" +
        token;
    service.service(url, { noPagination }, dispatch, "post", (data) => {
      if (data && data.isSuccessful)
        dispatch({ type: EXPORTDATA, payload: data });
    });
  };
