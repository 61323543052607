const API_URL = process.env.REACT_APP_API_URI;
const uiVersion = 2.02;
import {
  ISLOADING,
  REQUESTAPPROVEWARNING,
  TOKENEXPIRED,
} from "../_redux/actions/types";
const apiHandler = async (
  data,
  url,
  method,
  dispatch,
  originalCallback,
  disableLoading
) => {
  !disableLoading && dispatch({ type: ISLOADING, isLoading: true });
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: data && JSON.stringify(data),
  };
  method == "get" && delete options.body;
  return fetch(API_URL + url, options)
    .then((response) => response.json())
    .then((res) => {
      dispatch({ type: ISLOADING, isLoading: false });
      return success(res, dispatch, {
        data: data,
        url: url,
        method: method,
        callback: originalCallback,
      });
    })
    .catch((error) => {
      dispatch({ type: ISLOADING, isLoading: false });
      return fialed(error);
    });
};

const success = async (res, dispatch, originalRequest) => {
  if (res.statusCode == 601) dispatchTokenExpired(dispatch, originalRequest);
  else if (res.statusCode == 407)
    dispatch({
      type: REQUESTAPPROVEWARNING,
      payload: { ...originalRequest.data, message: res.error },
    });
  else return handleResponse(res, originalRequest);
};
const dispatchTokenExpired = (dispatch, originalRequest) => {
  dispatch({
    type: TOKENEXPIRED,
    payload: { tokenExpired: true, originalRequest: originalRequest },
  });
  return { err: true };
};
const handleResponse = (res, originalRequest) => {
  if (res.ui_version && res.ui_version > uiVersion)
    window.location.reload(true);
  if (originalRequest.url.includes("receipt"))
    return { isPrint: true, res: res };
  if (
    res &&
    !res.isSuccessful &&
    res.additionalInfo &&
    typeof res.additionalInfo.info == "string" &&
    res.additionalInfo.info.includes("Ecobank payments API is offline")
  )
    return res;
  else if (!res.isSuccessful) return { err: true, info: res };
  else return res;
};
const fialed = (res) => {
  return { error: true, info: res };
};

export default apiHandler;
