import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
/**
 * @param {Boolean} show - to show or hide modal
 * @param {function} hideHandler - function to handle show or hide modal
 * @param {Component} body  - body of modal
 * @param {Component} footer - footer of modal
 * @returns
 */
export const ItemeModal = (props) => {
  return (
    <Modal
      show={props.show}
      size={props.size || "sm"}
      onHide={props.hideHandler}
      backdrop={props.backdrop || "static"}
      centered={props.centered ? true : null}
    >
      <Header
        onHide={props.hideHandler}
        isIteme={props.isIteme}
        fphandler={props.fphandler ? props.fphandler : null}
        title={props.title}
      />
      <Modal.Body>{props.body}</Modal.Body>
      {props.footer || props.header}
    </Modal>
  );
};

/**
 *
 * @param {boolean} isIteme
 * @param {Function} onHide
 * @param {Function} fphandler - forgot password handler
 * @returns
 */
export const Header = (props) => {
  return (
    <Modal.Title id="contained-modal-title-vcenter">
      <div className="iteme-modal-header">
        {props.title && <h2 className="pull-left">{props.title}</h2>}
        {props.isIteme ? null : (
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
              props.onHide();
              props.fphandler ? props.fphandler({ close: true }) : null;
            }}
            className="beltIcon close"
          />
        )}
      </div>
    </Modal.Title>
  );
};
