import {
  GETBILLERDETAILSUCCESS,
  GETVALIDATEBILLERPAYEMENTSUCCESS,
  PAYEMENTFEE,
  CLEAR,
  DUPLICATEWARNING,
  GETAIRTIMEBILLERS,
} from "../actions/types";
const initialState = {
  biller:
    localStorage.getItem("biller") == "undefined"
      ? {}
      : JSON.parse(localStorage.getItem("biller")),
  billFormData: [],
  validateInfo: false,
  successInfo: {},
  failInfo: {},
  details: {},
  feeInfo: {
    amount: 0,
  },
  isDuplicateWarning: false,
  airtimeBillers: [],
};

const billerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GETBILLERDETAILSUCCESS:
      const { formData, detail } = getData(payload);
      return {
        ...state,
        billFormData: formData,
        details: detail,
      };
    case PAYEMENTFEE:
      return { ...state, feeInfo: payload };
    case GETVALIDATEBILLERPAYEMENTSUCCESS:
      return { ...state, validateInfo: payload.validateInfo };
    case DUPLICATEWARNING:
      return {
        ...state,
        isDuplicateWarning: action.payload,
      };
    case GETAIRTIMEBILLERS:
      return { ...state, airtimeBillers: action.payload };
    case CLEAR:
      return clearState(state);
    default:
      return state;
  }
};

export default billerReducer;

const getData = (payload) => {
  let formData = payload.details.billFormData;
  let detail = payload.details;
  {
    detail.billerCategory == "DONATION" ? formData.splice(1, 1) : "";
  }
  return { formData, detail };
};

const clearState = (state) => {
  return {
    ...state,
    billFormData: [],
    validateInfo: false,
    successInfo: {},
    failInfo: {},
    details: {},
    feeInfo: {
      amount: 0,
    },
  };
};
