import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addItem, clearState, fetchData } from "../../_redux/storeSlice";

const Search = (props) => {
  const [searchBy, setSearchBy] = useState("");
  const changeHandler = (e) => {
    setSearchBy(e.target.value);
  };
  return (
    <div className="pull-right">
      <InputGroup className="inputGroup">
        <FormControl
          value={searchBy}
          placeholder="Type to search"
          className="input"
          type="text"
          onChange={changeHandler}
          onKeyPress={(e) => {
            if (e.key == "Enter") props.setSearchBy(searchBy);
          }}
        />
        <InputGroup.Text
          className="inputIcon"
          onClick={() => props.setSearchBy(searchBy)}
        >
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Text>
      </InputGroup>
    </div>
  );
};
export const SearchInput = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(({ storeReducer }) => storeReducer).data;
  return (
    <div className="table-search">
      <FontAwesomeIcon icon={faSearch} className="table-search-icon" />
      <input
        autoComplete="off"
        placeholder="search"
        value={props.searchValue}
        onChange={(e) => {
          props.onChangeHandler && props.onChangeHandler(e.target.value);
          props.setSearchValue(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key == "Enter" && !props.item.isFilter)
            onClickHandler(props, dispatch);
        }}
        onKeyDown={(e) => {
          if (props.setActiveItem && e.keyCode == 40) {
            props.setActiveItem(
              props.active_item === data.length - 1 ? 0 : props.active_item + 1
            );
          } else if (props.setActiveItem && e.keyCode == 38) {
            props.setActiveItem(
              props.active_item === 0 ? data.length - 1 : props.active_item - 1
            );
          } else if (props.setActiveItem && e.keyCode == 13) {
            onClickHandler(
              props,
              dispatch,
              data[props.active_item].id,
              data[props.active_item].value
            );
          }
        }}
        id={props.id}
        className="table-search-input"
      ></input>
    </div>
  );
};
export const onClickHandler = (props, dispatch, value, label) => {
  props.searchItem.length == 0 && props.setSearchItem([props.item]);
  const item = props.item;
  item.value = label || props.searchValue;
  const searchItems = [...props.searchItem];
  const filteredItem = searchItems.filter((i) => i.label != props.item.label);
  props.setSearchItem([...filteredItem, item]);
  dispatch(
    addItem({
      item: props.item,
      value: value || props.searchValue,
    })
  );
  props.setSearchValue && props.setSearchValue("");
};
export const handleSearch = (setSearchBy, item) => {
  const searchObj = useSelector(({ storeReducer }) => storeReducer).search;
  useEffect(() => {
    setSearchBy(searchObj);
    document.getElementById(item.label).style.display = "none";
  }, [searchObj]);
};
export const SearchDropDown = (props) => {
  const dispatch = useDispatch();
  return (
    <div
      className={`child-item ${props.active ? "active" : ""}`}
      onClick={() => {
        onClickHandler(props, dispatch, props.element.id, props.element.value);
        dispatch(clearState());
      }}
    >
      <span>{props.element.value}</span>
      <br></br>
    </div>
  );
};

export const FilterInput = (props) => {
  const dispatch = useDispatch();
  const [active_item, setActiveItem] = useState(-1);
  const onChangeHandler = (value) => {
    if (value && value.length >= 4) {
      dispatch(fetchData({ text: value, url: "/users/suggest" }));
      setActiveItem(-1);
    } else dispatch(clearState());
  };
  const data = useSelector(({ storeReducer }) => storeReducer).data;
  return (
    <>
      <SearchInput
        {...props}
        onChangeHandler={onChangeHandler}
        setActiveItem={setActiveItem}
        active_item={active_item}
      />
      {data &&
        data.map((item, i) => {
          return (
            <div key={item.id}>
              <SearchDropDown
                element={item}
                {...props}
                active={i == active_item ? true : false}
              />
            </div>
          );
        })}
    </>
  );
};
export default Search;
