import React, { useEffect, useState } from "react";
import {
  faBox,
  faLockOpen,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoginForm = (props) => {
  const message = useSelector(({ message }) => message.message);
  const passWordReducer = useSelector(({ passWordReducer }) => passWordReducer);
  const { t } = useTranslation();
  return (
    <>
      {passWordReducer.passWordChanged ? (
        <h4 className="text-success text-center">
          {t("passwordChangedMessage")}
        </h4>
      ) : null}
      <h3 className="loginTitle">{t("loginTitle")}</h3>
      <h4 className="error text-center">{message || props.error}</h4>

      <CustomForm {...props} />
    </>
  );
};

const CustomForm = ({
  onChangeMobile,
  onChangePassword,
  onkeyPressHandler,
}) => {
  const onChangeMobileNo = (e) => {
    const mobile_no = e.target.value;
    setMobileNo(mobile_no);
    onChangeMobile(mobile_no);
  };

  const onChangePass = (e) => {
    const password = e.target.value;
    setPassword(password);
    onChangePassword(password);
  };
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  return (
    <>
      <MobileInput onChangeMobileNo={onChangeMobileNo} mobileNo={mobileNo} />
      <PasswordInput
        password={password}
        onChangePass={onChangePass}
        onkeyPressHandler={onkeyPressHandler}
        placeholder="Password"
      />
    </>
  );
};
export const MobileInput = (props) => {
  let nameInput;
  useEffect(() => {
    nameInput.focus();
  }, [nameInput]);
  const focusHandler = (input) => {
    nameInput = input;
  };
  return (
    <InputGroup size="lg" className="inputGroup">
      {/* <InputGroup.Text id="inputGroup-sizing-lg" className="inputIcon">
        <FontAwesomeIcon icon={props.placeholder ? faBox : faPhoneAlt} />
      </InputGroup.Text> */}
      <FormControl
        value={props.mobileNo}
        onChange={props.onChangeMobileNo}
        placeholder={props.placeholder || "phone number"}
        className="input"
        ref={focusHandler}
        autoComplete="off"
      />
    </InputGroup>
  );
};
export const PasswordInput = ({
  password,
  onChangePass,
  onkeyPressHandler,
  placeholder,
  focusHandler,
  className,
  max
}) => {
  const keyPress = (e) => {
    if (e.key == "Enter") onkeyPressHandler();
  };
  return (
      <FormControl
        value={password}
        placeholder={placeholder}
        className={`input ${className}`}
        type={!className ? "password" : "text"}
        onChange={onChangePass}
        onKeyPress={keyPress}
        ref={focusHandler ? focusHandler : null}
        maxLength={max||""}
      />
  );
};
export default LoginForm;
