import Cookies from "js-cookie";
import { _isAgent } from "../_constant/user_rights";
import { isCustomerCheck } from "../_redux/actions/flow";
import { getGames } from "../_redux/actions/inzozi";
import { getTariff } from "../_redux/actions/services";
import {
  WALLET,
  PROFILE,
  TERMS,
  TIMEWARNING,
  NOTIFICATION,
} from "../_redux/actions/types";
import { serviceHandler } from "../_redux/common";
import apiHandler from "./apiHandler";
import { hasStatus } from "../_constant/user_status";

const login = async (mobile_no, password, dispatch) => {
  const data = { mobile_no: mobile_no.toString(), password: password };
  const url = "/users/login";
  try {
    const response = await apiHandler(data, url, "post", dispatch);
    checkBrowserTime(response.timestamp, dispatch);
    if (response.result && response.result.token) {
      Cookies.set("user", JSON.stringify(response.result), {
        sameSite: "None",
        secure: true,
      });
      //localStorage.setItem("user", JSON.stringify(response.result));
      await profile(response.result.token, dispatch);
      const user_rights = JSON.parse(
        localStorage.getItem("profile")
      ).user_rights;
      const superAgent_id = JSON.parse(
        localStorage.getItem("profile")
      ).superagent_id;
      if (_isAgent(user_rights, "TRANSACT") && !superAgent_id) {
        dispatch(getGames());
        dispatch(getTariff());
        await isCustomerCheck(dispatch);
      }
    }
    return response;
  } catch (er) {
    return er;
  }
};
export const profile = async (token, dispatch) =>
  handleResponse(true, dispatch, token);

export const wallet = async (token, dispatch) =>
  handleResponse(false, dispatch, token);

const handleResponse = async (isProfile, dispatch, token) => {
  const route = isProfile ? "profile" : "wallet";
  const url = "/users/" + route + "?authtoken=" + token;
  try {
    const response = await apiHandler("", url, "get", dispatch, null, true);
    if (response.result) {
      localStorage.setItem(
        isProfile ? "profile" : "wallet",
        JSON.stringify(response.result)
      );
      dispatch({
        type: isProfile ? PROFILE : WALLET,
        payload: response.result,
      });
      if (
        !hasStatus(response.result?.user_status, "ACCEPTED_TERMS_V1") &&
        isProfile
      ) {
        dispatch({ type: TERMS, payload: true });
      }
    }
  } catch (e) {
    return e;
  }
};

const logout = () => {
  localStorage.clear();
  Cookies.remove("*");
};
const resetPassWordOtp = async (mobile_no, email, dispatch) => {
  const url = "/users/password/identify";
  const data = { mobile_no: mobile_no.toString(), email: email };
  try {
    const response = await apiHandler(data, url, "post", dispatch);
    return response;
  } catch (er) {
    return er;
  }
};
const otpVerify = async (otp, dispatch) => {
  const url = "/token/otp/validate";
  try {
    const response = await apiHandler({ otp: otp }, url, "post", dispatch);
    return response;
  } catch (er) {
    return er;
  }
};
const changePasswordOtp = async (password, otp, mobile_no, dispatch) => {
  const url = "/users/password/reset/otp";
  try {
    const response = await apiHandler(
      { otp: otp, password: password, mobile_no },
      url,
      "post",
      dispatch
    );
    return response;
  } catch (er) {
    return er;
  }
};

const checkBrowserTime = (timestamp, dispatch) => {
  const browserDate = new Date();
  const apiDate = new Date(timestamp);
  const diffTime = Math.abs(apiDate - browserDate);
  if (diffTime > 600000) dispatch({ type: TIMEWARNING, payload: true });
  else dispatch({ type: TIMEWARNING, payload: false });
};
export const getNotification = () => async (dispatch) => {
  return serviceHandler(
    NOTIFICATION,
    "/users/notifications?authtoken=",
    dispatch,
    "get"
  );
};
export default {
  login,
  logout,
  resetPassWordOtp,
  otpVerify,
  changePasswordOtp,
};
