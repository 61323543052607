import React, { useState } from "react";
import LoginForm from "./LoginForm";
import { ItemeModal } from "../_common/Modal";
import { useDispatch } from "react-redux";
import { login } from "../../_redux/actions/auth";
import { clearMessage } from "../_common/common";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
export default function Login(props) {
  const dispatch = useDispatch();
  clearMessage(dispatch);
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const onChangeMobile = (mobile) => {
    setMobileNo(mobile);
  };
  const onChangePassword = (pass) => {
    setPassword(pass);
  };
  const handleLogin = () => {
    if (mobileNo && password) dispatch(login(mobileNo, password));
    else setError("Please enter both MobileNo and Password!!");
  };
  return (
    <LoginUI
      {...props}
      handleLogin={handleLogin}
      onChangeMobile={onChangeMobile}
      onChangePassword={onChangePassword}
      error={error}
    />
  );
}
const LoginUI = (props) => {
  return (
    <ItemeModal
      show={props.show}
      size={props.islogin ? "sm" : "lg"}
      backdrop="static"
      centered={true}
      hideHandler={props.onHide}
      fphandler={props.fphandler}
      body={<LoginForm {...props} onkeyPressHandler={props.handleLogin} />}
      footer={<Footer {...props} />}
    />
  );
};
const Footer = (props) => {
  const { t } = useTranslation();
  return (
    <Modal.Footer className="d-flex justify-content-center">
      {props.islogin ? (
        <>
          {" "}
          <small className="link" onClick={props.fphandler}>
            {t("forgotPassword")}
          </small>
          <button
            title="Login"
            className="iteme-button"
            onClick={() => props.handleLogin()}
            style={{ width: "100%" }}
          >
            {t("loginButton")}
          </button>
        </>
      ) : (
        ""
      )}
    </Modal.Footer>
  );
};
