import { LISTNOTIFICATION, NOTIFICATION } from "../actions/types";
import { setPagination } from "../common";

const initialState = {
  notifications: [],
  list: [],
  totalRows: 0,
  page: 1,
  orderBy: "",
  orderDirection: "",
};

export const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION:
      return { ...state, notifications: action.payload };
    case LISTNOTIFICATION:
      setPagination(state, action);
      return { ...state, list: action.payload.result };
    default:
      return state;
  }
};
