import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
let ICONS = {};
const DynamicIcon = ({ iconName }) => {
  if (!ICONS[iconName])
    ICONS[iconName] = require(`@fortawesome/free-solid-svg-icons`)[iconName];
  return <FontAwesomeIcon icon={ICONS[iconName]} />;
};

export default DynamicIcon;
