import {
    CLEAR,
    SWLPOLICIES,
    SWLFEES,
  } from "../actions/types";
  
  export const SonarwaLifeReducer = (
    state = { policiesInfo: null, fees: null },
    action
  ) => {
    switch (action.type) {
      case SWLPOLICIES:
        return { ...state, policiesInfo: action.payload };
      case SWLFEES:
        return { ...state, fees: action.payload };
      case CLEAR:
        return { ...state, policiesInfo: null, fees: null };
      default:
        return state;
    }
  };
  