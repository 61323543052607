import { CLEAR, BALANCE, VALIDATECARD, TAPANDGOCARDS } from "../actions/types";

export const TapAndGoReducer = (
  state = { balance: {}, validateInfo: null, cards: null },
  action
) => {
  switch (action.type) {
    case TAPANDGOCARDS:
      return { ...state, cards: action.payload };
    case VALIDATECARD:
      return { ...state, validateInfo: action.payload };
    case BALANCE:
      return { ...state, balance: action.payload };
    case CLEAR:
      return { ...state, validateInfo: null, balance: null, cards: null };
    default:
      return state;
  }
};
