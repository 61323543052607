import {
  GAMESLIST,
  GAMELISTFAIL,
  GETPLAYEDGAMESUCCESS,
  CHECKCLAIMSUCCESS,
  GETRESULTSUCCESS,
  PLAYGAMESUCCESS,
} from "./types";
import service from "../../_services/service";
import { filterFun } from "../common";
import { success } from "../common";
import Cookies from "js-cookie";
export const getGames = () => (dispatch) => {
  const url = "/carousel/getGames?authtoken=";

const token = JSON.parse(Cookies.get("user")).token
  service.service(url + token, "", dispatch, null, (data_1) => {
    if (data_1 && data_1.isSuccessful) {
      localStorage.setItem("games", JSON.stringify(data_1.result.games));
      dispatch({ type: GAMESLIST, payload: data_1.result.games });
    } else dispatch({ type: GAMELISTFAIL });
  });
};

// this will be called for play,cancel
export const playGamesOrCancel =
  ({ data, url, successMessage, pin }) =>
  async (dispatch) => {
  
  const token = JSON.parse(Cookies.get("user")).token
    data.pin = pin;
    service.service(
      url + token,
      data,
      dispatch,
      null,
      (data_1) => {
        if (data_1 && data_1.isSuccessful)
          if (url.includes("play"))
            dispatch({
              type: PLAYGAMESUCCESS,
              payload: {
                data_1: data_1,
              },
            });
          else success(dispatch, successMessage);
      },
      { stayWhenError: true }
    );
  };
export const claim =
  ({ data, url, successMessage, pin }) =>
  async (dispatch) => {
    data.pin = pin;
  
  const token = JSON.parse(Cookies.get("user")).token
    service.service(url + token, data, dispatch, null, (data_1) => {
      if (data_1 && data_1.isSuccessful)
        success(dispatch, successMessage, false);
    });
  };
export const checkClaim = (data) => async (dispatch) => {
  const url = "/carousel/checkClaim?authtoken=";

const token = JSON.parse(Cookies.get("user")).token
  service.service(url + token, data, dispatch, null, (data_1) => {
    if (data_1 && data_1.isSuccessful) {
      dispatch({ type: CHECKCLAIMSUCCESS, payload: data_1.result });
    }
  });
};

export const getResults = () => async (dispatch) => {
  const url = "/carousel/getResults?authtoken=";

const token = JSON.parse(Cookies.get("user")).token
  service.service(url + token, "", dispatch, null, (data_1) => {
    if (data_1 && data_1.isSuccessful) {
      dispatch({ type: GETRESULTSUCCESS, payload: data_1 });
    }
  });
};

export const list = (data) => async (dispatch) => {
  const filter = filterFun(data.start, data.end);

const token = JSON.parse(Cookies.get("user")).token
  const url =
    "/carousel/list?$pagesize=" +
    data.pageSize +
    "&$pagenumber=" +
    data.pageNo +
    filter +
    "&$orderby=" +
    data.orderBy +
    "&$orderdir=" +
    data.orderDirection +
    "&authtoken=" +
    token;
  service.service(url, "", dispatch, null, (data_1) => {
    if (data_1 && data_1.isSuccessful) {
      dispatch({ type: GETPLAYEDGAMESUCCESS, payload: data_1 });
    }
  });
};

export const sendTickectSMS = (data) => (dispatch) => {
  const url = "/carousel/sendTicketSMS?authtoken=";

const token = JSON.parse(Cookies.get("user")).token
  service.service(url + token, data, dispatch, null, (data_1) => {
    if (data_1 && data_1.isSuccessful) {
      success(dispatch, "SMS was sent successfully");
    }
  });
};
