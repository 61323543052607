import {
  BRANDINGORDERS,
  BRANDINGPRODUCTS,
  BRANDINGPRODUCTSCATEGORIES,
  CLEARPAGE,
  TRAININGVIDEOS,
} from "../actions/types";

const initialState = {
  totalRows: 0,
  page: 1,
  orderBy: "",
  orderDirection: "",
  orders: [],
  videos: [],
};

const trainingReducer = (state = initialState, action) => {
  let list;
  if (action.payload && action.payload.collection) {
    state.totalRows = action.payload.collection.totalResult;
    state.page = action.payload.collection.pageNumber;
    state.orderBy = action.payload.collection.orderBy;
    state.orderDirection = action.payload.collection.orderDirection;
    list = action.payload.result;
  }
  switch (action.type) {
    case TRAININGVIDEOS:
      return {
        ...state,
        videos: list,
      };
    case CLEARPAGE:
      return {
        ...state,
        page: 1,
        videos: [],
      };
    default:
      return state;
  }
};

export default trainingReducer;
