const USER_STATUS = {
  CREATED_BY_BACKOFFICE: {
    id: 0,
    description: "User account was created through the backoffice.",
  },
  SELF_SIGNUP: {
    id: 1,
    description: "User account was created through self-signup.",
  },
  VISITED_BY_TSM: {
    id: 2,
    description: "User was visited by our TSM for onboarding.",
  },
  KYC_CHECKED: {
    id: 3,
    description: "The user's KCY was checked successfully.",
  },
  ACCEPTED_TERMS_V1: {
    id: 4,
    description:
      "The user accepted the inital (2021) version of the terms & conditions.",
  },
  MOBILE_VERIFIED: {
    id: 5,
    description: "The user verified his or her mobile number.",
  },
  EMAIL_VERIFIED: {
    id: 6,
    description: "The user verified his or her email address.",
  },
  CREATED_BY_SUPERAGENT: {
    id: 7,
    description: "User account was created by a superagent.",
  },
  APPLIED_FOR_SUPERAGENT: {
    id: 8,
    description: "User applied for becoming superagent.",
  },
};
Object.keys(USER_STATUS).forEach(
  (k) => (USER_STATUS[k].bitmap = BigInt(1) << BigInt(USER_STATUS[k].id))
);

export const hasStatus = (role, status) =>
  USER_STATUS[status] ? (BigInt(role) & USER_STATUS[status].bitmap) > 0 : false;
