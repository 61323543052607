import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "./Categories";
import { scroll } from "../_common/common";
import { useLocation } from "react-router";
import Header from "../Agents/Header";
import { useSelector } from "react-redux";
export default function About({ privacy, terms }) {
  const isLoggedIn = useSelector(({ auth }) => auth).isLoggedIn;
  const location = useLocation();
  useEffect(() => {
    scroll();
  }, [location.pathname]);
  const { t } = useTranslation();
  let content = [
    privacy
      ? {
          title: t("privacy.privacy"),
          body: t("privacy.privacyBody"),
        }
      : {
          title: t("itemeIntro.title"),
          body: t("itemeIntro.body"),
        },
    {
      title: privacy ? t("privacy.information") : t("itemeIntro.vision"),
      body: privacy ? t("privacy.informationBody") : t("itemeIntro.visionBody"),
    },
    {
      title: privacy ? t("privacy.cookies") : t("itemeIntro.mission"),
      body: privacy ? t("privacy.cookiesBody") : t("itemeIntro.missionBody"),
    },
  ];
  return (
    <>
      {!isLoggedIn ? <Header isFooter={true} /> : null}
      <Row aboutContent={content} terms={terms} />
    </>
  );
}
