import { CLEARPAGE, INVOICES, VALIDATEPAY } from "../actions/types";

const initialState = {
  list: [],
  validateInfo: {},
  page: 1,
  totalRows: 0,
  orderBy: "",
  orderDirection: "",
};

const PayReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVOICES:
      const result = action.payload.result;
      state.totalRows = result.collection.totalResult;
      state.page = result.collection.pageNumber;
      state.orderBy = result.collection.orderBy;
      state.orderDirection = result.collection.orderDirection;
      const ar = result && result.result && result.result.results;
      return {
        ...state,
        list: ar || [],
      };
    case VALIDATEPAY:
      return {
        ...state,
        validateInfo: action.payload,
      };
    case CLEARPAGE:
      return { ...state, page: 1, validateInfo: {} };
    default:
      return state;
  }
};

export default PayReducer;
