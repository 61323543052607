import { serviceHandler } from "../common";
import {  PROVIDERS, SERVICES, TARIFF, VENDORS } from "./types";

export const getServices = () => async (dispatch) => {
  serviceHandler(SERVICES, "/services/test?authtoken=", dispatch, "get");
};

export const getTariff = () => async (dispatch) => {
  serviceHandler(TARIFF, `/services/tariffs?authtoken=`, dispatch, "post");
};
export const listServices = () => async (dispatch) => {
  serviceHandler(
    SERVICES,
    "/services/list?authtoken=",
    dispatch,
    "get",
    null,
    null,
    async (data) => {
      const tags = [];
      const services = filterService(
        data.result,
        localStorage.getItem("flowStatus")
      );
      localStorage.setItem("services", JSON.stringify(services));
      services.forEach((key) => {
        key.tags.forEach((element) => {
          !tags.includes(element) && tags.push(element);
        });
      });
      localStorage.setItem("tags", JSON.stringify(tags));
      setTimeout(() => {
        // TODO: this is likely the reason for the "blank page" error
        // localStorage.removeItem("services");
        // localStorage.removeItem("tags");
        dispatch(refreshServices());
      }, 300000); // 900000
    }
  );
};
export const refreshServices = () => async (dispatch) => {
  dispatch(listServices());
};
const remove = (ar, tobeRemoved) => {
  const arIndex = [];
  tobeRemoved.forEach((element) => {
    const index = ar.findIndex((item) => item.code == element);
    index > -1 && arIndex.push(index);
  });
  arIndex.forEach((index) => ar.splice(index, 1));
  return ar;
};
const filterService = (items, flowStatus) => {
  if (JSON.parse(localStorage.getItem("profile"))?.superagent_id)
    items = remove(items, ["FLOW_LIST", "FLOW_REQUEST", "FLOW_SIGN_UP"]);
  else if (flowStatus == "approved") items = remove(items, ["FLOW_SIGN_UP"]);
  else items = remove(items, ["FLOW_LIST", "FLOW_REQUEST"]);
  return items;
};



export const getVendors = () => async (dispatch) => {
  serviceHandler(VENDORS, `/services/getVendors?authtoken=`, dispatch, "post");
};

export const getProviders = () => async (dispatch) => {
  serviceHandler(
    PROVIDERS,
    `/services/getProviders?authtoken=`,
    dispatch,
    "post"
  );
};

