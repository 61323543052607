import {
  CLEAR,
  GETCUSTOMERACCOUNT,
  GETVALIDATECASHTOKEN,
  VIEWCOMMISSIONFEE,
  REMITTANCEVALIDATEACCOUNT,
  REMITTANCEGETRATE,
} from "../actions/types";
const initialState = {
  details: {},
  commission: {},
  validateCashToken: {},
  validateAccountInfo: {},
};

const bankingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETCUSTOMERACCOUNT:
      return { ...state, details: action.payload.acountInfo };
    case VIEWCOMMISSIONFEE:
      return {
        ...state,
        commission: action.payload.commission,
        amount: action.payload.amount,
      };
    case GETVALIDATECASHTOKEN:
      return { ...state, validateCashToken: action.payload };
    case REMITTANCEVALIDATEACCOUNT:
      return { ...state, validateAccountInfo: action.payload };
    case REMITTANCEGETRATE:
      return { ...state, rateInfo: action.payload };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default bankingReducer;
