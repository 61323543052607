import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Categories from "../Agents/dashboard/Categories";
import Notifications from "../Agents/dashboard/Notifications";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faSearch } from "@fortawesome/free-solid-svg-icons";
import adminDashboard, {
  getAdminServices,
} from "../../_constant/adminDashbaord";
import { adminCategories } from "../../_constant/dashboardCategories";
import { _hasRight } from "../../_constant/admin_rights";
import { scroll } from "../_common/common";
import { clearSearch } from "../../_redux/storeSlice";
import { _isAgent } from "../../_constant/user_rights";
import { CLEAR } from "../../_redux/actions/types";

const AdminDashboard = () => {
  const profile = useSelector(({ walletReducer }) => walletReducer).profile;
  const aReducer = useSelector(({ walletAdminReducer }) => walletAdminReducer);
  const [services, setServices] = useState(adminDashboard(profile));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearSearch());
    dispatch({ type: CLEAR });
  }, []);
  const [favorites, setFavorites] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  return (
    <div className="dashboard">
      <Categories
        setIsSearching={setIsSearching}
        setServices={setServices}
        profile={profile}
        setFavorites={setFavorites}
        isAdmin={true}
        categories={adminCategories}
        pendingRequest={aReducer.pendingRequest}
      />
      <div>
        <div className="mb-3">
          <Search
            setIsSearching={setIsSearching}
            setServices={setServices}
            profile={profile}
            setFavorites={setFavorites}
          />
        </div>
        <div className="services-container">
          <Services
            services={services}
            setServices={setServices}
            setFavorites={setFavorites}
            profile={profile}
          />
        </div>
      </div>
      <Notifications />
    </div>
  );
};

const Services = (props) => {
  const navigate = useNavigate();
  return props.services.map((item, i) => {
    return (
      item && (
        <div
          className="dashboard-service"
          key={i}
          draggable={true}
          onDragStart={(e) => {
            e.dataTransfer.setData("text/plain", item.code);
            e.target.classList.add("dashboard-service-dragged");
          }}
          onDragEnd={(e) => {
            e.target.classList.remove("dashboard-service-dragged");
          }}
          onClick={() => _clickHandler(item, navigate, props)}
        >
          <Logo item={item} />
        </div>
      )
    );
  });
};

const _clickHandler = (item, navigate, props) => {
  if (item.filter_by_vendor) {
    props.setServices(
      getAdminServices.filter(
        (i) =>
          i.vendor.code == item.code &&
          i.group_by_vendor &&
          (_hasRight(props.profile?.admin_rights, i.rights) ||
            _isAgent(props.profile?.user_rights, i.rights))
      )
    );
    props.setFavorites([]);
    scroll();
  } else navigate(`/admin/${item.path}`);
};

const Logo = ({ item, favs }) => {
  return item ? (
    <div
      onDragEnter={(e) => {
        e.preventDefault();
      }}
      style={{ textAlign: "center" }}
    >
      {favs && <FontAwesomeIcon icon={faHeart} color="#ccc" />}
      {item.badge}
      <FontAwesomeIcon
        icon={item.icon}
        className="dashboard-icon"
        draggable="false"
      />
      <p> {item.name}</p>
    </div>
  ) : (
    ""
  );
};
const Search = (props) => {
  const [search, setSearch] = useState("");
  const [tags, setTags] = useState([]);
  const [active_item, setActiveItem] = useState(-1);
  // searching(search, props, setTags);
  return (
    <div className="service-search">
      <FontAwesomeIcon icon={faSearch} />
      <input
        placeholder="Search"
        className="input"
        // onChange={(e) => {
        //   setSearch(e.target.value);
        //   setActiveItem(-1);
        // }}
        // onKeyDown={(e) => {
        //   if (setActiveItem && e.keyCode == 40) {
        //     setActiveItem(
        //       active_item === tags.length - 1 ? 0 : active_item + 1
        //     );
        //   } else if (setActiveItem && e.keyCode == 38) {
        //     setActiveItem(
        //       active_item === 0 ? tags.length - 1 : active_item - 1
        //     );
        //   } else if (setActiveItem && e.keyCode == 13)
        //     searchClickHandler(props, tags[active_item], setTags);
        // }}
      />
      {/* <div className="dashboard-search" id="tags">
        {tags.map((key, i) => {
          return (
            <div
              key={i}
              className={`${i == active_item ? "active" : ""}`}
              onClick={() => searchClickHandler(props, key, setTags)}
            >
              <span>{key}</span>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};
export default AdminDashboard;
