import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getServices, searchClickHandler } from "./Dashboard";

const Search = (props) => {
  const [search, setSearch] = useState("");
  const [tags, setTags] = useState([]);
  const [active_item, setActiveItem] = useState(-1);
  searching(search, props, setTags);
  return (
    <div className="service-search">
      <FontAwesomeIcon icon={faSearch} />
      <input
        placeholder="Search"
        className="input"
        onChange={(e) => {
          setSearch(e.target.value);
          setActiveItem(-1);
        }}
        onKeyDown={(e) => {
          if (setActiveItem && e.keyCode == 40) {
            setActiveItem(
              active_item === tags.length - 1 ? 0 : active_item + 1
            );
          } else if (setActiveItem && e.keyCode == 38) {
            setActiveItem(
              active_item === 0 ? tags.length - 1 : active_item - 1
            );
          } else if (setActiveItem && e.keyCode == 13)
            searchClickHandler(props, tags[active_item], setTags);
        }}
      />
      <div className="dashboard-search" id="tags">
        {tags?.map((key, i) => {
          return (
            <div
              key={i}
              className={`${i == active_item ? "active" : ""}`}
              onClick={() => searchClickHandler(props, key, setTags)}
            >
              <span>{key}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const searching = (search, props, setTags) => {
  useEffect(() => {
    if (search.length > 0) {
      const allTags = JSON.parse(localStorage.getItem("tags"));
      const filterdTags = allTags?.filter((item) =>
        item.toLowerCase().includes(search.toLowerCase())
      );
      setTags(filterdTags);
      document.getElementById("tags").style.display = "block";
    } else clearTag(props, setTags);
  }, [search]);
};
const clearTag = (props, setTags) => {
  document.getElementById("tags").style.display = "none";
  setTags([]);
  props.setIsSearching(false);
  //adjust this to work on admin dashbaord
  getServices(props.setServices, props.setFavorites, [], props.profile);
};
export default Search;
