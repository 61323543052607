import Cookies from "js-cookie";
import apiHandler from "../../_services/apiHandler";
import service from "../../_services/service";
import { actionHandler, filterFun, success } from "../common";
import { getSearchFilter } from "./admin/common";
import {
  FLOWRESIGN,
  FLOWSTATUS,
  ISCUSTOMER,
  FLOWAPPLY,
  ADVANCEPRODUCTS,
  ADVANCEHISTORY,
} from "./types";
export const isCustomer = () => async (dispatch) => {
  const url = "/flow/isCustomer?authtoken=";

const token = JSON.parse(Cookies.get("user")).token
  service.service(url + token, "", dispatch, null, (data_1) => {
    if (data_1) {
      dispatch({ type: ISCUSTOMER, payload: data_1.result });
      if (data_1.result && data_1.result.isCustomer) {
        dispatch(getCustomerStatus());
      }
    }
  });
};

export const getCustomerStatus = () => async (dispatch) => {
  const url = "/flow/getCustomerStatus?authtoken=";
  actionHandler(url, "", FLOWSTATUS, dispatch, flowTypeHandler);
};

export const apply = () => async (dispatch) => {
  const url = "/flow/apply?authtoken=";
  actionHandler(url, "", FLOWAPPLY, dispatch, flowTypeHandler);
};

export const resign = () => async (dispatch) => {
  const url = "/flow/resign?authtoken=";
  actionHandler(url, "", FLOWRESIGN, dispatch, flowTypeHandler);
};
const action1 = {
  type: ISCUSTOMER,
  payload: { isCustomer: true },
};
const action2 = {
  type: FLOWSTATUS,
  payload: { status: "pending" },
};
export const getAdvanceProducts = () => async (dispatch) => {
  const url = "/flow/getAdvanceProducts?authtoken=";

const token = JSON.parse(Cookies.get("user")).token
  service.service(url + token, "", dispatch, null, (data_1) => {
    if (data_1) dispatch({ type: ADVANCEPRODUCTS, payload: data_1.result });
  });
};
export const requestAdvance = (product) => async (dispatch) => {
  const url = "/flow/requestAdvance?authtoken=";
  const user=JSON.parse(Cookies.get("user"));
  service.service(
    url + user.token,
    { product_id: product.product_id, pin: product.pin },
    dispatch,
    null,
    (data_1) => {
      if (data_1)
        success(dispatch, "FA requested successfully", "flow/history");
    }
  );
};

export const getAdvanceHistory =
  ({ searchBy, pageNo, pageSize, orderBy, orderDirection, start, end }) =>
  async (dispatch) => {
  
  const token = JSON.parse(Cookies.get("user")).token
    const search = getSearchFilter(searchBy);
    const filter = filterFun(start, end);
    const url =
      "/flow/getAdvanceHistory?$pagesize=" +
      pageSize +
      "&$pagenumber=" +
      pageNo +
      filter +
      "&$orderby=" +
      orderBy +
      "&$orderdir=" +
      orderDirection +
      "&authtoken=" +
      token;
    service.service(url, search, dispatch, null, (data_1) => {
      if (data_1) dispatch({ type: ADVANCEHISTORY, payload: data_1 });
    });
  };

export const flowTypeHandler = (type, dispatch, data_1) => {
  switch (type) {
    case ISCUSTOMER:
      dispatch({
        type: ISCUSTOMER,
        payload: data_1.result,
      });
      break;
    case FLOWSTATUS:
      dispatch({
        type: FLOWSTATUS,
        payload: data_1.result,
      });
      break;
    case FLOWAPPLY:
      dispatch(action1);
      dispatch(action2);
      break;
    default:
      break;
  }
};

export const repayAdvance = (item) => async (dispatch) => {
  const url = "/flow/repayAdvance?authtoken=";
  // const token = JSON.parse(localStorage.getItem("user")).token;
const token = JSON.parse(Cookies.get("user")).token
  service.service(
    url + token,
    { application_id: item.application_id, pin: item.pin },
    dispatch,
    null,
    (data_1) => {
      if (data_1) {
        dispatch(
          getAdvanceHistory({
            pageNo: 1,
            pageSize: 10,
            orderBy: "",
            orderDirection: "",
          })
        );
        success(dispatch, "FA repayed successfully", "flow/history");
      }
    }
  );
};

// used on new dashbaord
export const isCustomerCheck = async (dispatch) => {
  const url = "/flow/isCustomer?authtoken=";

const token = JSON.parse(Cookies.get("user")).token
  const response = await apiHandler(
    "",
    url + token,
    "post",
    dispatch,
    null,
    true
  );
  if (response.result?.isCustomer) {
    const data = await apiHandler(
      "",
      "/flow/getCustomerStatus?authtoken=" + token,
      "post",
      dispatch,
      null,
      true
    );
    const status = data.result && data.result.status;
    localStorage.setItem("flowStatus", status);
  }
  localStorage.setItem("isCustomer", response.result.isCustomer);
};
