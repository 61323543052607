import {
  CLEAR,
  MTNPROVIDERS,
  MTNVALIDATE,
  MTNFEES,
} from "../actions/types";

export const MTNReducer = (
  state = { providers: [], validateInfo: null, fees: null },
  action
) => {
  switch (action.type) {
    case MTNPROVIDERS:
      return { ...state, providers: action.payload };
    case MTNVALIDATE:
      return { ...state, validateInfo: action.payload };
    case MTNFEES:
      return { ...state, fees: action.payload };
    case CLEAR:
      return { ...state, validateInfo: null, providers: null };
    default:
      return state;
  }
};
