import {
  CLEAR,
  AIRTELFEES,
  AIRTELPROVIDERS,
  AIRTELVALIDATE,
} from "../actions/types";

export const AIRTELReducer = (
  state = { providers: [], validateInfo: null, fees: null },
  action
) => {
  switch (action.type) {
    case AIRTELPROVIDERS:
      return { ...state, providers: action.payload };
    case AIRTELVALIDATE:
      return { ...state, validateInfo: action.payload };
    case AIRTELFEES:
      return { ...state, fees: action.payload };
    case CLEAR:
      return { ...state, validateInfo: null, providers: null };
    default:
      return state;
  }
};
