import {
  CREDITREQUESTCOUNT,
  CREDITREQUESTSUCCESS,
  HISTORYSUCCESS,
  USERWALLETSUCCESS,
  ECOBANK_FINACIALSTATUS,
  PINDO_FINACIALSTATUS,
  URUBUTO_FINACIALSTATUS,
  FLOW_FINACIALSTATUS,
  STARTIMES_FINACIALSTATUS,
  ITEME_FINACIALSTATUS,
  CANAL_FINACIALSTATUS,
  POSTSUCCESS,
  LISTAGENTTOAGENT,
  SIMILARREQUEST,
  FDI_FINACIALSTATUS,
  ACGROUP_FINACIALSTATUS
} from "../types";
import { filterFun, serviceHandler, success } from "../../common";
import { callBack, handler } from "./common";
//getting pending credit request
export const listCreditRequest =
  ({ pageNo, pageSize, orderBy, searchBy, orderDirection }) =>
  async (dispatch) => {
    handler(
      "/creditrequest/listPending?$pagesize=" +
        pageSize +
        "&$pagenumber=" +
        pageNo +
        "&$orderby=" +
        orderBy +
        "&$orderdir=" +
        orderDirection +
        "&authtoken=",
      dispatch,
      CREDITREQUESTSUCCESS,
      "",
      searchBy
    );
  };

//getting credit request history
export const history =
  ({ pageNo, pageSize, orderBy, searchBy, start, end, orderDirection }) =>
  async (dispatch) => {
    const filter = filterFun(start, end);
    handler(
      "/creditrequest/history?$pagesize=" +
        pageSize +
        "&$pagenumber=" +
        pageNo +
        filter +
        "&$orderby=" +
        orderBy +
        "&$orderdir=" +
        orderDirection +
        "&authtoken=",
      dispatch,
      HISTORYSUCCESS,
      "",
      searchBy
    );
  };

//list af users wallets
export const getWalletList =
  ({ pageNo, pageSize, orderBy, searchBy, orderDirection }) =>
  async (dispatch) => {
    handler(
      "/wallet/list?$pagesize=" +
        pageSize +
        "&$pagenumber=" +
        pageNo +
        "&$orderby=" +
        orderBy +
        "&$orderdir=" +
        orderDirection +
        "&authtoken=",
      dispatch,
      USERWALLETSUCCESS,
      "",
      searchBy
    );
  };
/* financial status of each vendors actions*/
const financialStatus = (dispatch, url, type) =>
  handler(url, dispatch, type, "", "", "get", { stayWhenError: true }, true);
export const getEcobankFinancialStatus = () => async (dispatch) =>
  financialStatus(
    dispatch,
    "/ERW/getFinancialStatus?authtoken=",
    ECOBANK_FINACIALSTATUS
  );

export const getUrubutoFinancialStatus = () => async (dispatch) =>
  financialStatus(
    dispatch,
    "/bkth/getFinancialStatus?authtoken=",
    URUBUTO_FINACIALSTATUS
  );

export const getPindoFinancialStatus = () => async (dispatch) =>
  financialStatus(
    dispatch,
    "/bulkSMS/getFinancialStatus?authtoken=",
    PINDO_FINACIALSTATUS
  );

export const getStartimesFinancialStatus = () => async (dispatch) =>
  financialStatus(
    dispatch,
    "/startimes/getFinancialStatus?authtoken=",
    STARTIMES_FINACIALSTATUS
  );

export const getFlowFinancialStatus = () => async (dispatch) =>
  financialStatus(
    dispatch,
    "/flow/getFinancialStatus?authtoken=",
    FLOW_FINACIALSTATUS
  );

export const getItemeFinancialStatus = () => async (dispatch) =>
  financialStatus(
    dispatch,
    "/admin/getFinancialStatus?authtoken=",
    ITEME_FINACIALSTATUS
  );

export const getCanalFinancialStatus = () => async (dispatch) =>
  financialStatus(
    dispatch,
    "/canalplus/getFinancialStatus?authtoken=",
    CANAL_FINACIALSTATUS
  );

export const settleWithEcobank = (data) => async (dispatch) => {
  serviceHandler(
    POSTSUCCESS,
    "/ERW/settleWithEcobank?authtoken=",
    dispatch,
    "post",
    data
  );
};

export const selfTrasnfer = (data) => async (dispatch) => {
  serviceHandler(
    POSTSUCCESS,
    "/ERW/selfTransfer?authtoken=",
    dispatch,
    "post",
    data
  );
};
export const listAgentToAngent =
  ({ pageNo, pageSize, start, end }) =>
  async (dispatch) => {
    const filter = filterFun(start, end);
    handler(
      "/wallet/listAgentToAgent?$pagesize=" +
        pageSize +
        "&$pagenumber=" +
        pageNo +
        filter +
        "&authtoken=",
      dispatch,
      LISTAGENTTOAGENT
    );
  };

export const undoApprove = (data) => async (dispatch) => {
  return serviceHandler(
    POSTSUCCESS,
    "/creditrequest/undoApprove?authtoken=",
    dispatch,
    "post",
    data,
    null,
    (data_1) => callBack(dispatch, data_1, "Success", listCreditRequest),
    { stayWhenError: true }
  );
};

//generate request
export const generateRequest = (data) => async (dispatch) => {
  serviceHandler(
    POSTSUCCESS,
    "/creditrequest/addAdminRequest?authtoken=",
    dispatch,
    "",
    data
  );
};

//generate request
export const approveByBankStatement = (data, setText) => async (dispatch) => {
  serviceHandler(
    null,
    "/creditrequest/approveByBankStatement?authtoken=",
    dispatch,
    "",
    data,
    null,
    (data_1) => {
      if (data_1.result?.approved) {
        success(dispatch, "Request approved successfully!", { stayOnC: true });
        setText("");
      } else {
        if (data_1.result?.wrongAmount) {
          success(
            dispatch,
            "Request rejected successfully! (wrong amount requested)",
            {
              stayOnC: true,
            }
          );
          setText("");
        }
        dispatch({
          type: SIMILARREQUEST,
          payload: {
            data:
              data_1.result.similarCorrectAmount.length > 0
                ? data_1.result.similarCorrectAmount
                : data_1.result.similarWrongAmount,
            approve: data_1.result.similarCorrectAmount.length > 0,
          },
        });
      }
    },
    { stayWhenError: true }
  );
};

export const getFDIFinancialStatus = () => async (dispatch) =>
  financialStatus(
    dispatch,
    "/fdi/getFinancialStatus?authtoken=",
    FDI_FINACIALSTATUS
  );

export const getACGroupFinancialStatus = () => async (dispatch) =>
  financialStatus(
    dispatch,
    "/acgroup/getFinancialStatus?authtoken=",
    ACGROUP_FINACIALSTATUS
  );
