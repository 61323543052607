import {
  GETAGENTSUCCESS,
  GETPRESIGNEDPUTURL,
  REMOVEFILE,
  GETKYCSUCCESS,
  CREDITREQUESTSUCCESS,
  USERDETAILS,
  HISTORYSUCCESS,
  CLEAR,
  KYCCOUNT,
  CREDITREQUESTCOUNT,
  ISMOBILE,
  GETADMINUSER,
  USERWALLETSUCCESS,
  GETTSM,
  CLEARUSERDETAILS,
  AGENTAPPLICATION,
  SIMILARREQUEST,
} from "../../actions/types";

const initialState = {
  details: {},
  page: 1,
  agents: [],
  users: [],
  totalRows: 0,
  files: [],
  kyc: [],
  pendingKyc: 0,
  isMobileAllowed: false,
  mobileErr: "",
  totalAgents: 0,
  tsm: [],
  applications: [],
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETAGENTSUCCESS:
      return {
        ...state,
        agents: action.payload.result,
        page: action.payload.collection.pageNumber,
        totalRows: action.payload.collection.totalResult,
        totalAgents: action.payload.collection.totalResult,
      };
    case GETPRESIGNEDPUTURL:
      return { ...state, files: [...state.files, action.payload] };
    case REMOVEFILE:
      return {
        ...state,
        files: state.files.filter((item) => item.file !== action.payload),
      };
    case GETKYCSUCCESS:
      return {
        ...state,
        kyc: action.payload.result,
        page: action.payload.collection.pageNumber,
        totalRows: action.payload.collection.totalResult,
      };
    case USERDETAILS:
      return {
        ...state,
        details: action.payload.result,
      };
    case CLEARUSERDETAILS:
      return { ...state, details: {} };
    case KYCCOUNT:
      return {
        ...state,
        pendingKyc: action.payload.result,
      };
    case ISMOBILE:
      return {
        ...state,
        isMobileAllowed: action.payload.isAllowed || action.payload.result,
        mobileErr: action.payload.message,
      };
    case GETADMINUSER:
      return {
        ...state,
        users: action.payload.result,
        page: action.payload.collection.pageNumber,
        totalRows: action.payload.collection.totalResult,
      };
    case AGENTAPPLICATION:
      return {
        ...state,
        applications: action.payload.result,
        page: action.payload.collection.pageNumber,
        totalRows: action.payload.collection.totalResult,
      };
    case GETTSM:
      return { ...state, tsm: action.payload.result };
    case CLEAR:
      return { ...state, page: 1, mobileErr: "", files: [], details: {} };
    default:
      return state;
  }
};

const initialStateWallet = {
  page: 1,
  totalRows: 0,
  requestList: [],
  history: [],
  pendingRequest: 0,
  wallets: [],
  similarRequest: [],
};

export const walletAdminReducer = (state = initialStateWallet, action) => {
  switch (action.type) {
    case CREDITREQUESTSUCCESS:
      return {
        ...state,
        requestList: action.payload.result,
        page: action.payload.collection.pageNumber,
        totalRows: action.payload.collection.totalResult,
      };
    case HISTORYSUCCESS:
      return {
        ...state,
        history: action.payload.result,
        page: action.payload.collection.pageNumber,
        totalRows: action.payload.collection.totalResult,
      };
    case CREDITREQUESTCOUNT:
      return {
        ...state,
        pendingRequest: action.payload.result,
      };
    case USERWALLETSUCCESS:
      return {
        ...state,
        wallets: action.payload.result,
        page: action.payload.collection.pageNumber,
        totalRows: action.payload.collection.totalResult,
      };
    case CLEAR:
      return { ...state, page: 1, similarRequest: [] };
    case SIMILARREQUEST:
      return {
        ...state,
        similarRequest: action.payload?.data,
        approve: action.payload?.approve,
      };
    default:
      return state;
  }
};
