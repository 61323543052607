import { CLEARPIN, REQUESTPIN, SETPIN } from "../actions/types";

const initialState = {
  show: false,
  pin: undefined,
  postAction: {},
  data: {},
};

export const pinReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUESTPIN:
      return {
        ...state,
        show: true,
        postAction: action.postAction,
        data: action.data,
      };
    case SETPIN:
      return { ...state, show: false, pin: action.pin };
    case CLEARPIN:
      return initialState
    default:
      return state;
  }
};
