import { useDispatch, useSelector } from "react-redux";
import { listAgentClaim } from "../../../_redux/actions/admin/claim";
import { TableRow } from "../../_common/Table";
import { Button, myHook, pagingService } from "../../_common/common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { CLEAR } from "../../../_redux/actions/types";

const GeneralClaim = ({ action, isAgent }) => {
  const cReducer = useSelector(({ claimReducer }) => claimReducer);
  const obj = myHook({
    page: cReducer.page,
    action: action || listAgentClaim,
    reload: cReducer.reloadList,
  });
  const range = pagingService(cReducer.totalRows, obj.pageSize);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="list">
      {isAgent && (
        <div className="pull-right mx-2">
          <Button
            className="iteme-button"
            onClickHandler={() => navigate("/newClaim")}
            name={t("NewClaim")}
          />
        </div>
      )}

      {cReducer.list && (
        <TableRow
          header={["Ticket Id", "Agent", "Pesorn in charge", "status", "Date"]}
          body={
            <TableBody isAgent={action ? false : true} cReducer={cReducer} />
          }
          range={range}
          page={cReducer.page}
          data={cReducer.list}
          title="Claim List"
          {...obj}
        />
      )}
    </div>
  );
};
const TableBody = ({ cReducer, isAgent }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: CLEAR });
  }, []);
  return (
    <>
      {cReducer.list.map((key, i) => {
        return (
          <tr
            key={i}
            className="sort-btn"
            onClick={() => _clickHandler(key, isAgent, navigate)}
          >
            <td>{key.ticket_id}</td>
            <td>{key?.agent?.first_name + " " + key?.agent?.last_name}</td>
            <td>
              {key?.person_in_charge?.first_name +
                " " +
                key?.person_in_charge?.last_name}
            </td>
            <td>{key.status}</td>
            <td>{new Date(key.at_created).toLocaleString("en-GB")}</td>
          </tr>
        );
      })}
    </>
  );
};
export default GeneralClaim;

export const _clickHandler = (claim, isAgent, navigate) => {
  /*
    TODO: display the page depending on type of claim ( payment_request, ...)
  */
  let url = "";
  let id =
    (claim.transaction && claim.transaction.txn_id) || claim.transaction_id;
  if (claim.transaction_id)
    url = isAgent
      ? `/transactionDetails/${id}`
      : `/admin/claims/transactionDetails/${id}`;
  else
    url = isAgent
      ? `/chat/${claim.id || claim.claim_id}`
      : `/admin/chat/${claim.id || claim.claim_id}`;
  navigate(url);
};
