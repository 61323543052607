import { LISTAGENTTOAGENT } from "../../actions/types";

const initialState = {
  list: [],
  page: 1,
  totalRows: 0,
};

export const A2AReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTAGENTTOAGENT:
      return {
        ...state,
        list: action.payload.result,
        page: action.payload.collection.pageNumber,
        totalRows: action.payload.collection.totalResult,
      };

    default:
      return state;
  }
};
