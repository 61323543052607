import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../_redux/actions/auth";
import { clearMessage } from "../../_common/common";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  clearMessage (dispatch);
  const message = useSelector(({ message }) => message.message);
  const handleLogin = () => {
    dispatch(login(username, password))
    .catch (() => {})
  };
  const usernameRef = useRef(null);
  const buttonRef = useRef(null);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      buttonRef.current.click();
    }
  };
  useEffect(() => {
    usernameRef.current.focus();
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  return (
    <div className="itm-form itm-white">
      <h2 className="mb-4">{t("loginTitle")}</h2>
      {props?.data?.success_message && <p className="itm-success">{props.data.success_message}</p>}
      {message && <p className="itm-error">{message}</p>}
      <div className="mb-3">
        <label htmlFor="username">{t("MobileNumber")}</label>
        <input
          type="text"
          ref={usernameRef}
          id="username"
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="password">{t("password")}</label>
        <input
          type="password"
          id="password"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <button
        type="submit"
        ref={buttonRef}
        className="w-100 itm-button mt-20"
        disabled={!username || !password}
        onClick={handleLogin}
      >
        {t("loginButton")}
      </button>
      <p
        className="itm-white itm-clickable pt-20 mt-4"
        onClick={() => props.changeView ('FORGOT')}
      >
        {t("forgotPassword")}
      </p>
    </div>
  );
};

export default Login;
