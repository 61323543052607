import {
  PASSBOOK,
  COMMISSION,
  CREDITREQUEST,
  CLEARPAGE,
  PASSBOOKSTATISTICS,
  PASSBOOKCLAIM,
  PASSBOOKDETAILS,
  EXPORTDATA,
} from "../actions/types";

const initialState = {
  passbook: [],
  commissions: [],
  creditRequest: [],
  totalRows: 0,
  page: 1,
  commissions: [],
  creditRequest: [],
  orderBy: "",
  orderDirection: "",
  passbookInfo: {},
  details: null,
};

const reportReducer = (state = initialState, action) => {
  let report;
  if (action.payload && action.payload.collection) {
    state.totalRows = action.payload.collection.totalResult;
    state.page = action.payload.collection.pageNumber;
    state.orderBy = action.payload.collection.orderBy;
    state.orderDirection = action.payload.collection.orderDirection;
    report = action.payload.result;
  }
  switch (action.type) {
    case PASSBOOK:
      return {
        ...state,
        passbook: report,
        passbookClaim: null,
      };
    case EXPORTDATA:
      return {
        ...state,
        exportData: action.payload.result,
      };
    case COMMISSION:
      return {
        ...state,
        commissions: report,
      };
    case CREDITREQUEST:
      return {
        ...state,
        creditRequest: report,
      };
    case PASSBOOKSTATISTICS:
      return { ...state, passbookInfo: action.payload };
    case PASSBOOKCLAIM:
      return {
        ...state,
        passbookClaim: Array.isArray(action.payload)
          ? action.payload[1][0]
          : action.payload,
      };
    case PASSBOOKDETAILS:
      return { ...state, details: action.payload };
    case CLEARPAGE:
      return { ...state, page: 1, passbookClaim: null, details: null };
    default:
      return state;
  }
};

export default reportReducer;
