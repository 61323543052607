import {
  CLEAR,
  SETCELL,
  SETDISTRICT,
  SETPROVINCE,
  SETSECTOR,
  SETVILLAGE,
} from "../actions/types";
const initialState = {
  provinces: [],
  districts: [],
  sectors: [],
  villages: [],
  cells: [],
};

export const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETPROVINCE:
      return { ...state, provinces: action.payload };
    case SETDISTRICT:
      return { ...state, districts: action.payload };
    case SETSECTOR:
      return { ...state, sectors: action.payload };
    case SETCELL:
      return { ...state, cells: action.payload };
    case SETVILLAGE:
      return { ...state, villages: action.payload };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
};
