import {
    DSTVVALIDATE,
    CLEAR,
    DSTVPACKAGES,
    DSTVFEES,
  } from "../actions/types";
  
  export const DstvReducer = (
    state = { validateInfo: null, packageInfo: [], fees: null },
    action
  ) => {
    switch (action.type) {
      case DSTVVALIDATE:
        return { ...state, validateInfo: action.payload };
      case DSTVPACKAGES:
        return { ...state, packageInfo: action.payload };
      case DSTVFEES:
        return { ...state, fees: action.payload };
      case CLEAR:
        return { ...state, validateInfo: null, packageInfo: [], fees: null };
      default:
        return state;
    }
  };
  