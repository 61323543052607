import {
  faCodeBranch,
  faComments,
  faDice,
  faHandHoldingUsd,
  faHome,
  faLandmark,
  faListAlt,
  faListOl,
  faMoneyBill,
  faUsers,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { searchClickHandler } from "../componets/Agents/dashboard/Dashboard";

let timeoutID = null;
const dragHandler = (e, props) => {
  e.target.classList.add("dashboard-categories-dragover");
  timeoutID = setTimeout(() => {
    searchClickHandler(props, "");
  }, 1000);
};
const dragLeaveHandler = (e) => {
  clearTimeout(timeoutID);
  e.target.classList.remove("dashboard-categories-dragover");
};
export const agentCategories = (props) => [
  {
    name: "",
    icon: faHome,
    drag: (e) => dragHandler(e, props),
    dragLeave: (e) => dragLeaveHandler(e, props),
  },
  { name: "Payment", icon: faMoneyBill },
  { name: "Banking", icon: faLandmark },
  { name: "Games", icon: faDice },
  { name: "Advance", icon: faHandHoldingUsd },
  { name: "Communication", icon: faComments },
];
export const adminCategories = (props) => [
  {
    name: "",
    icon: faHome,
    drag: (e) => dragHandler(e, props),
    dragLeave: (e) => dragLeaveHandler(e, props),
  },
  { name: "Agents", icon: faUsers },
  { name: "Wallets", icon: faWallet },
  { name: "List", icon: faListAlt },
  { name: "Iteme", icon: faListOl },
  { name: "Claims", icon: faComments },
  { name: "Branding", icon: faCodeBranch },
];
