import React from "react";
import rtn from "../../images/rtn_logo_gray.png";
import iteme from "../../images/iteme_logo_gray.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function FooterHome() {
  return (
    <footer className="iteme-footer">
      <div className="row">
        <RTN />
        <Address />
        <Links />
        <ITEME />
      </div>
      <FooterBottom />
    </footer>
  );
}

const FooterBottom = () => {
  return (
    <div className="col-12">
      <span>© Copyright RTN {new Date().getFullYear()}</span>
    </div>
  );
};
const Address = () => {
  const { t } = useTranslation();
  return (
    <div className="col-2">
      <h4>{t("header.contact")}</h4>
      <p>
        Headoffice:
        <br /> Savana Centre
        <br /> Nyamata, Bugesera District
        <br /> Opposite Nyamata Market
      </p>
      <p>
        Mail:
        <br /> info@rtn.rw
      </p>
      <p>
        Phone:
        <br /> +250 788 352 325
        <br /> +250 781 845 508
        <br /> +250 784 825 387
      </p>
    </div>
  );
};

const RTN = () => {
  const { t } = useTranslation();
  return (
    <div className="col-4">
      <div
        className="footer-logo"
        style={{ backgroundImage: `url(${rtn})` }}
      ></div>
      <p>{t("footer.rtn")}</p>
      <LinkList isFooter={true} />
    </div>
  );
};
export const LinkList = ({ isFooter }) => {
  const links = [
    {
      link: "https://www.facebook.com/Iteme-605821380071770",
      icon: "fa fa-facebook-square",
    },
    { link: "https://twitter.com/iteme_rw", icon: "fa fa-twitter" },
    {
      link: "https://www.instagram.com/iteme_rw/",
      icon: "fa fa-instagram",
    },
  ];
  return (
    <ul className={isFooter ? "footer-social-list" : "home-social-list"}>
      {links.map((link, i) => {
        return (
          <li key={i}>
            <a href={link.link}>
              <i className={link.icon}></i>
            </a>
          </li>
        );
      })}
    </ul>
  );
};
const ITEME = () => {
  const { t } = useTranslation();
  return (
    <div className="col-4">
      <div
        className="footer-logo"
        style={{ backgroundImage: `url(${iteme})` }}
      ></div>
      <p>{t("footer.iteme")}</p>
      <div className="subscribe-form"></div>
    </div>
  );
};
const Links = () => {
  const { t } = useTranslation();
  const links = [
    { to: "/", label: t("header.home") },
    { to: "/about", label: t("header.about") },
    { to: "/terms", label: t("header.terms") },
    { to: "/Privacy", label: t("header.privacy") },
  ];
  return (
    <div className="col-2">
      <h4>Site Links</h4>
      <Nav links={links} />
    </div>
  );
};

const Nav = ({ links }) => {
  return (
    <>
      {links.map((list, i) => {
        return (
          <p key={i}>
            <Link to={list.to} title={list.label}>
              {list.label}
            </Link>
          </p>
        );
      })}
    </>
  );
};
export default FooterHome;
