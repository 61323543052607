import {
  STARTIMESVALIDATE,
  CLEAR,
  STARTIMESSUBSCRIBERINFO,
  STARTIMESOPTIONS,
} from "../actions/types";

export const StartimesReducer = (
  state = { validateInfo: null, subscriberInfo: null },
  action
) => {
  switch (action.type) {
    case STARTIMESVALIDATE:
      return { ...state, validateInfo: action.payload };
    case STARTIMESSUBSCRIBERINFO:
      return { ...state, subscriberInfo: action.payload };
    case STARTIMESOPTIONS:
      const obj = state.subscriberInfo;
      obj.options = action.payload;
      return { ...state, subscriberInfo: obj };
    case CLEAR:
      return { ...state, subscriberInfo: null, validateInfo: null };
    default:
      return state;
  }
};
