import {
  CLEAR,
  REGPROVIDERS,
  REGVALIDATE,
  REGFEES,
} from "../actions/types";

export const REGReducer = (
  state = { providers: [], validateInfo: null, fees: null },
  action
) => {
  switch (action.type) {
    case REGPROVIDERS:
      return { ...state, providers: action.payload };
    case REGVALIDATE:
      return { ...state, validateInfo: action.payload };
    case REGFEES:
      return { ...state, fees: action.payload };
    case CLEAR:
      return { ...state, validateInfo: null, providers: null,fees:null };
    default:
      return state;
  }
};
