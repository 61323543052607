import {
  GETBILLERDETAILSUCCESS,
  GETVALIDATEBILLERPAYEMENTSUCCESS,
  POSTSUCCESS,
  PAYEMENTFEE,
  DUPLICATEWARNING,
  GETAIRTIMEBILLERS,
  BILLERVALID,
} from "./types";
import { serviceHandler, success } from "../common";
import { actionHandler } from "../common";

export const getBillerDetails = (data) => async (dispatch) => {
  actionHandler(
    "/ERW/getBillerDetails?authtoken=",
    { billerCode: data },
    GETBILLERDETAILSUCCESS,
    dispatch,
    billerTypeHandler
  );
};

export const getValidateBillpayment = (data) => async (dispatch) => {
  actionHandler(
    "/ERW/getValidateBillpayment?authtoken=",
    data,
    GETVALIDATEBILLERPAYEMENTSUCCESS,
    dispatch,
    billerTypeHandler,
    { stayWhenError: true }
  );
};

export const isStillValid = (data) => async (dispatch) => {
  actionHandler(
    "/ERW/getValidateBillpayment?authtoken=",
    data,
    BILLERVALID,
    dispatch,
    billerTypeHandler,
    { stayWhenError: true }
  );
};

export const postBillPayment = (data) => async (dispatch) => {
  actionHandler(
    `/ERW/postBillPayment?authtoken=`,
    data,
    POSTSUCCESS,
    dispatch,
    billerTypeHandler,
    { stayWhenError: true }
  );
};

const billerTypeHandler = (type, dispatch, data_1) => {
  switch (type) {
    case GETBILLERDETAILSUCCESS:
      dispatch({
        type: GETBILLERDETAILSUCCESS,
        payload: { details: data_1.result },
      });
      break;
    case GETVALIDATEBILLERPAYEMENTSUCCESS:
      dispatch({
        type: GETVALIDATEBILLERPAYEMENTSUCCESS,
        payload: { validateInfo: data_1.result },
      });
      break;
    case POSTSUCCESS:
      success(dispatch, null, { stayOnC: false });
      break;
    case PAYEMENTFEE:
      dispatch({
        type: PAYEMENTFEE,
        payload: data_1.result,
      });
      break;
    case BILLERVALID:
      success(dispatch, "Id is still valid", { stayOnC: true });
      break;
  }
};

export const getbillpaymentfee = (data) => async (dispatch) => {
  actionHandler(
    "/ERW/getbillpaymentfee?authtoken=",
    data,
    PAYEMENTFEE,
    dispatch,
    billerTypeHandler
  );
};

export const duplicateWarning = (dispatch) => {
  dispatch({
    type: DUPLICATEWARNING,
    payload: true,
  });
};

export const getAirtimeBillers = () => async (dispatch) => {
  serviceHandler(
    GETAIRTIMEBILLERS,
    "/ERW/getAirtimeBillers?authtoken=",
    dispatch,
    "post"
  );
};

export const buyairtime = (data) => async (dispatch) => {
  serviceHandler(
    POSTSUCCESS,
    "/ERW/buyairtime?authtoken=",
    dispatch,
    "POST",
    data
  );
};
