import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changePassOtp } from "../../../_redux/actions/auth";
import { clearMessage } from "../../_common/common";
import { checkPasswords } from "../../../_services/password";

const EnterNewPassword = (props) => {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  clearMessage (dispatch);
  const message = useSelector(({ message }) => message.message);
  const handleSetPassword = () => {
    dispatch(changePassOtp (password, props.data.otp, props.data.mobile))
    .then (() => props.changeView ('LOGIN', {success_message: "Password was successfully changed. You can now login."}))
    .catch (() => {})
    // props.changeView ('LOGIN', {success_message: "Password was successfully changed. You can now login."})
  };
  let check = checkPasswords (password, password2);
  const passwordRef = useRef(null);
  const buttonRef = useRef(null);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      buttonRef.current.click();
    }
  };
  useEffect(() => {
    passwordRef.current.focus();
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  return (
    <div className="itm-form itm-white">
      <h2 className="mb-4">{t("ChangePassWord")}</h2>
      {check.error && <p className="itm-error">{check.error}</p>}
      {message && <p className="itm-error">{message}</p>}
      <div className="mb-3">
        <label htmlFor="password">{t("Newpassword")}</label>
        <input
          type="password"
          ref={passwordRef}
          id="password"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="password2">{t("Confirmpassword")}</label>
        <input
          type="password"
          id="password2"
          onChange={(e) => setPassword2(e.target.value)}
          required
        />
      </div>
      <button
        type="submit"
        ref={buttonRef}
        className="w-100 itm-button mt-20"
        disabled={!check.ok}
        onClick={handleSetPassword}
      >
        {t("submit")}
      </button>
      <p
        className="itm-white itm-clickable pt-20 mt-4"
        onClick={() => props.changeView ('LOGIN')}
      >
        {t("loginTitle")}
      </p>
    </div>
  );
};

export default EnterNewPassword;
