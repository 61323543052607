import {
  CLEAR,
  BALANCE,
  KTRNBUNDLES,
  VALIDATEKTRN,
  VALIDATECUSTOMER,
} from "../actions/types";

export const KtrnReducer = (
  state = { bundles: [], balance: {}, validateInfo: null, customerInfo: null },
  action
) => {
  switch (action.type) {
    case KTRNBUNDLES:
      return { ...state, bundles: action.payload };
    case VALIDATEKTRN:
      return { ...state, validateInfo: action.payload };
    case BALANCE:
      return { ...state, balance: action.payload };
    case VALIDATECUSTOMER:
      return { ...state, customerInfo: action.payload };
    case CLEAR:
      return { ...state, validateInfo: null, bundles: [], customerInfo: null };
    default:
      return state;
  }
};
