import Cookies from "js-cookie";
import { ISLOADING } from "./types";
import { isAuthenticated } from "./auth";

const API_URL = process.env.REACT_APP_API_URI;
export const printReceipt = (id, lang, route) => async (dispatch) => {
  const token = JSON.parse(Cookies.get("user")).token;

  // TODO: check first if session is still active - e.g. by calling another endpoint

  const url = route
    ? `${route}/${id}?lang=${lang}&authtoken=${token}`
    : `/transaction/receipt/${id}?lang=${lang}&authtoken=${token}`;
  //await dispatch (isAuthenticated());
  dispatch({ type: ISLOADING, isLoading: true });
  fetch(API_URL + url)
    .then((res) => res.arrayBuffer())
    .then((data) => {
      dispatch({ type: ISLOADING, isLoading: false });
      let file = new Blob([data], { type: "application/pdf" });
      let fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("target", "_blank");
      link.setAttribute("open", "iteme_receipt.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch((er) => console.log(er));
};
