import React from "react";
import { useTranslation } from "react-i18next";
import { TableRow } from "../../_common/Table";
import { pagingService } from "../../_common/common";
import { _isAgent } from "../../../_constant/user_rights";
const header = (t, backBtn) => [
  { label: t("Date"), order: "at_modified" },
  { label: "Txn. ID", searchBy: "$all" },
  t("Status"),
  { label: t("Service"), searchBy: "$all" },
  { label: "Info", searchBy: "$all" },
  { label: t("Amount"), order: "closing_balance" },
  { label: t("Balance"), order: "balance" },
  !backBtn ? "Action" : "",
];

const Report = (props) => {
  const range = pagingService(props.rows, props.pageSize);
  const { t } = useTranslation();
  let data = props.data;
  const pHeader = header(t, props.backBtn);
  if (props.profile && _isAgent(props.profile.user_rights, "IS_SUPERAGENT")) {
    pHeader.pop(t("Action"));
    pHeader.push(t("DoneBy"));
    pHeader.push(t("Action"));
  }
  return (
    <div className="list">
      <TableRow
        {...props}
        range={range}
        data={data}
        header={props.header ? props.header : pHeader}
        isPassBook={props.isPassBook}
      />
    </div>
  );
};

export default Report;
