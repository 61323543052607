import {
  faInfo,
  faPhoneAlt,
  faQuestionCircle,
  faSms,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { RowUi } from "./Header";
import { _hasRight } from "../../_constant/admin_rights";

const Help = ({ hideMobileMenu, rights }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateTo = (link) => {
    hideMobileMenu();
    navigate(link);
  };
  const row = [
    {
      name: t("ITEMETARIFF"),
      icon: faInfo,
      onClickHandler: () => navigateTo("tariff"),
    },
    {
      name: t("Contact Us"),
      icon: faPhoneAlt,
      onClickHandler: () => navigateTo("contact"),
    },
    {
      name: t("Claim"),
      icon: faSms,
      onClickHandler: () => navigateTo("claim"),
    },
    {
      name: t("Training"),
      icon: faUserGraduate,
      onClickHandler: () => navigateTo("video"),
    },
  ];

  return (
    <div className="header-button">
      <FontAwesomeIcon icon={faQuestionCircle} className="mr-5" />
      {t("Help")}
      <div className="header-submenu">
        <RowUi row={row} />
      </div>
    </div>
  );
};

export default Help;
