import {
  AGENTCLAIMS,
  CLAIMDETAILS,
  CLEAR,
  CLEARPAGE,
  PASSBOOKCLAIM,
  TRANSACTIONCLAIM,
} from "../actions/types";

const initialState = {
  totalRows: 0,
  page: 1,
  orderBy: "",
  orderDirection: "",
  list: [],
};

const claimReducer = (state = initialState, action) => {
  let list;
  if (action.payload && action.payload.collection) {
    state.totalRows = action.payload.collection.totalResult;
    state.page = action.payload.collection.pageNumber;
    state.orderBy = action.payload.collection.orderBy;
    state.orderDirection = action.payload.collection.orderDirection;
    list = action.payload.result;
  }
  switch (action.type) {
    case AGENTCLAIMS:
      return {
        ...state,
        list: list,
      };
    case PASSBOOKCLAIM:
      return {
        ...state,
        reloadList: true,
        chat: Array.isArray(action.payload)
          ? action.payload[1][0]
          : action.payload,
        details: action.payload,
      };
    case CLAIMDETAILS:
      return { ...state, details: action.payload };
    case TRANSACTIONCLAIM:
      return {
        ...state,
        details: action.payload,
      };
    case CLEARPAGE:
      return {
        ...state,
        page: 1,
        list: null,
        reloadList: false,
        details: null,
      };
    case CLEAR:
      return { ...state, details: null };
    default:
      return state;
  }
};

export default claimReducer;
