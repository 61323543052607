import React from "react";

const Pagination = props => {
  return (
    <>
      {props.currentPage !== 1 ? <PaginationButton {...props} page={1} /> : ""}
      {props.currentPage > 2 ? <PaginationButton {...props} hideOnMobile={true} page={2} /> : ""}

      {props.currentPage > 5 ? <PaginationDots /> : ""}
      {props.currentPage > 4 ? <PaginationButton {...props} hideOnMobile={true} page={props.currentPage - 2} /> : ""}
      {props.currentPage > 3 ? <PaginationButton {...props} page={props.currentPage - 1} /> : ""}
      <PaginationButton {...props} page={props.currentPage} selected={true} />
      {props.currentPage < props.totalPages - 2 ? <PaginationButton {...props} page={props.currentPage + 1} /> : ""}
      {props.currentPage < props.totalPages - 3 ? <PaginationButton {...props} hideOnMobile={true} page={props.currentPage + 2} /> : ""}
      {props.currentPage < props.totalPages - 4 ? <PaginationDots /> : ""}

      {props.currentPage < props.totalPages - 1 ? <PaginationButton {...props} hideOnMobile={true} page={props.totalPages - 1} /> : ""}
      {props.currentPage !== props.totalPages ? <PaginationButton {...props} page={props.totalPages} /> : ""}
    </>
  );
};

const PaginationButton = props => (
  <button
    type="button"
    className={`iteme-pagination-button ${props.hideOnMobile ? 'hide-on-mobile' : ''}`}
    disabled={props.selected ? true : false}
    onClick={() => props.pagingHandler (props.page)}
  >
  {props.page}
  </button>
);

const PaginationDots = () => (
  <span className="iteme-pagination-dots">...</span>
);

export default Pagination;
