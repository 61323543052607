import React from "react";

 const SectionHeader=(props)=>{
    return (
        <div className="row">
        <div className="col-xs-12">
            <div className="section-black text-center">
                <h2 className="text-secondary font-stag">
                    <span className="left-line-black"></span> {props.title}
                    <span className="right-line-black"></span>
                </h2>
            </div>
        </div>
    </div>
    )
}

export default SectionHeader;