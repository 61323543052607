import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const ServicesRow = (props) => {
    return (
        <div className="">
            <div className="row services">
                {props.services.map((service, i) => {
                    return (

                        <div className="col" key={i}>
                            <div className="service-items" >

                                <div className="service-image" >
                                    {service.icon ? <FontAwesomeIcon className="service-icon" icon={service.icon} size="4x" /> : ''}

                                    <p > {service.name}</p>
                                </div>
                            </div>
                        </div>

                    )
                })}
            </div>
            </div>
     
    )
}

export default ServicesRow;