import {
  GETBASKET,
  LISTBASKETS,
  SUBMITBASKET,
  STOREBASKETDATA,
  ENDBASKET,
} from "../actions/types";
const initialState = {
  baskets: [],
  basket: {},
  newbasket: {},
  submitM: null,
  data: {},
  transaction_type: null,
  showSPinner: false,
  successRate: 0,
  submitted: false,
};
const BasketReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTBASKETS:
      return { ...state, baskets: action.payload };
    case GETBASKET:
      return { ...state, basket: action.payload };
    case SUBMITBASKET:
      return {
        ...state,
        showSPinner: true,
        submitted: true,
        successRate: action.payload.rate,
      };
    case STOREBASKETDATA:
      return {
        ...state,
        data: action.payload,
        transaction_type: action.transaction_type,
      };
    case ENDBASKET:
      return {
        ...state,
        showSPinner: action.payload || false,
        successRate: 0,
      };
    default:
      return state;
  }
};

export default BasketReducer;
