import React, { useState } from "react";
import { Tabs, Tab, Form } from "react-bootstrap";
import { ItemeTerms } from "../user/Terms";
import { useDispatch } from "react-redux";
import { apply } from "../../_redux/actions/flow";
import { useTranslation } from "react-i18next";
import { terms as termsAction } from "../../_redux/actions/auth";
import { Button } from "./common";
/**
/**
 *@param {boolean} isIteme
 * @param {Array} tabs - The number of tabs and their description: title,data,eventkey.
 * @returns
 */
export const ItemeTabForm = (props) => {
  return (
    <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      {props.tabs.map((item, i) => {
        let body = "";
        if (props.isIteme) body = <ItemeTerms data={item.data} />;
        else body = item.data;
        return (
          <Tab
            eventKey={item.eventKey}
            title={item.title}
            className="tab-content"
            key={i}
          >
            <div className="flow_intro">{body}</div>
          </Tab>
        );
      })}
     
    </Tabs>
  );
};
// for submitting any  terms and conditions from
/**
 *
 * @param {boolean} isIteme
 * @param {Function} hideHandler
 * @returns
 */
export const Footer = (props) => {
  const { t } = useTranslation();
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  let disable;
  if (!terms && !props.isIteme) disable = true;
  else if (!terms || (!privacy && props.isIteme)) disable = true;
  else disable = false;
  const dispatch = useDispatch();
  const checkBox = [
    { label: t("AcceptTermsandConditions"), value: terms, handle: setTerms },
    { label: t("AcceptPrivacyPolicy"), value: privacy, handle: setPrivacy },
  ];
  const clickHandler = () => {
    if (props.isIteme) {
      dispatch(termsAction());
    } else dispatch(apply());
    props.hideHandler ? props.hideHandler() : null;
  };
  props.isIteme ? checkBox : checkBox.pop();
  return (
    <FooterUi
      checkBox={checkBox}
      clickHandler={clickHandler}
      disable={disable}
      isIteme={props.isIteme}
    />
  );
};
const FooterUi = ({ checkBox, clickHandler, disable, isIteme }) => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="row">
        {checkBox.map((item, i) => {
          return (
            <div className="col" key={i}>
              <Form.Check
                type="checkbox"
                id={"default-checkbox"}
                label={item.label}
                onChange={(e) => item.handle(e.target.checked)}
                value={item.value}
              />
            </div>
          );
        })}
      </div>
      <div className="col-6">
        <Button
          name={isIteme ? "Accept" : t("Accept")}
          onClickHandler={clickHandler}
          disable={disable}
        />
      </div>
    </div>
  );
};
