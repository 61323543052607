import { combineReducers } from "redux";
import auth, { walletReducer, passWordReducer } from "./auth";
import loadingReducer from "./_global";
import message from "./message";
import billerReducer from "./biller";
import reportReducer from "./report";
import bankingReducer from "./banking";
import RequestCreditReducer from "./requestCredit";
import inzoziReducer from "./inzozi";
import { flowReducer } from "./flow";
import { userReducer, walletAdminReducer } from "./admin/admin";
import { transactionREducer } from "./admin/transaction";
import { financialStatus } from "./admin/financialSReducer";
import { WarningReducer } from "./warningReducer";
import { AgentActivityReducer } from "./admin/agentActivity";
import { TransferReducer } from "./walletTransfer";
import { SuperAreducer } from "./admin/superAreducer";
import { A2AReducer } from "./admin/agentToagent";
import BasketReducer from "./basket";
import BulkReducer from "./bulksms";
import { NotificationReducer } from "./notification";
import { pinReducer } from "./pinReducer";
import { ServicesReducer } from "./services";
import PayReducer from "./pay";
import { UserReducer } from "./user";
import storeReducer from "../storeSlice";
import { UrubutoReducer } from "../reducers/urubuto";
import { CanalPlusReducer } from "../reducers/canalplus";
import { StartimesReducer } from "../reducers/startimes";
import claimReducer from "../reducers/claim";
import brandingReducer from "../reducers/branding";
import RRAReducer from "../reducers/RRA";
import { locationReducer } from "./location";
import trainingReducer from "./training";
import { KtrnReducer } from "./KTRN";
import { TapAndGoReducer } from "./TapAndGo";
import { REGReducer } from "./reg";
import { MTNReducer } from "./mtn";
import { AIRTELReducer } from "./airtel";
import { DstvReducer } from "./dstv";
import { SonarwaLifeReducer } from "./sonarwa_life";
export default combineReducers({
  auth,
  message,
  loadingReducer,
  billerReducer,
  reportReducer,
  bankingReducer,
  walletReducer,
  RequestCreditReducer,
  passWordReducer,
  inzoziReducer,
  flowReducer,
  userReducer,
  walletAdminReducer,
  transactionREducer,
  financialStatus,
  WarningReducer,
  AgentActivityReducer,
  TransferReducer,
  SuperAreducer,
  BasketReducer,
  A2AReducer,
  BulkReducer,
  NotificationReducer,
  pinReducer,
  ServicesReducer,
  PayReducer,
  UserReducer,
  storeReducer,
  UrubutoReducer,
  CanalPlusReducer,
  StartimesReducer,
  claimReducer,
  brandingReducer,
  RRAReducer,
  locationReducer,
  trainingReducer,
  KtrnReducer,
  TapAndGoReducer,
  REGReducer,
  MTNReducer,
  AIRTELReducer,
  DstvReducer,
  SonarwaLifeReducer
});
