import { URUBUTOFEE, URUBUTOVALIDATE, CLEAR } from "../actions/types";

export const UrubutoReducer = (
  state = { validateInfo: null, feeInfo: null },
  action
) => {
  switch (action.type) {
    case URUBUTOVALIDATE:
      return { ...state, validateInfo: action.payload };
    case URUBUTOFEE:
      return { ...state, feeInfo: action.payload };
    case CLEAR:
      return { ...state, validateInfo: null, feeInfo: null };
    default:
      return state;
  }
};
