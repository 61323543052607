import {
  CLEAR,
  FLOWSTATUS,
  ISCUSTOMER,
  ADVANCEPRODUCTS,
  ADVANCEHISTORY,
  FLOWCUSTOMERS,
  FLOWLOANS,
} from "../actions/types";
import { setPagination } from "../common";

const initialState = {
  flowStatus: null,
  isCustomer: null,
  products: [],
  loanhistory: [],
  totalRows: 0,
  page: 1,
  orderBy: "",
  orderDirection: "",
  customers: [],
  loans: [],
};

export const flowReducer = (state = initialState, action) => {
  switch (action.type) {
    case FLOWSTATUS:
      return { ...state, flowStatus: action.payload };
    case ISCUSTOMER:
      return { ...state, isCustomer: action.payload };
    case ADVANCEPRODUCTS:
      return { ...state, products: action.payload.products };
    case ADVANCEHISTORY:
      setPagination(state, action);
      return {
        ...state,
        loanhistory: action.payload.result,
      };
    case FLOWCUSTOMERS:
      setPagination(state, action);
      return {
        ...state,
        customers: action.payload.result,
      };
    case FLOWLOANS:
      setPagination(state, action);
      return {
        ...state,
        loans: action.payload.result,
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
};


