const API_URL = process.env.REACT_APP_API_URI;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { arraysAreEqual } from "../componets/_common/common";
import Cookies from "js-cookie";
export const fetchData = createAsyncThunk("data/fetchData", async (data) => {
  const options = {
    method: data.method || "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: data && JSON.stringify(data),
  };

const token = JSON.parse(Cookies.get("user")).token
  const response = await fetch(
    `${API_URL + data.url}?authtoken=${token}`,
    options
  );
  const res = await response.json();
  return res;
});
const storeSlice = createSlice({
  name: "app",
  initialState: {
    search: {},
    watatu: [],
    data: [],
    isLoading: true,
    status: "",
    message: "",
    searchObj: {},
  },
  reducers: {
    addItem: (state, action) => {
      const item = action.payload.item || {};
      if (!item.isFilter && !item.useFilter) {
        state.searchObj[`${item.searchBy}`] = action.payload.value;
        state.search["$search"] = state.searchObj;
      } else if (item.isFilter || item.useFilter) {
        state.searchObj = {};
        state.searchObj[`${item.searchBy}`] = action.payload.value;
        state.search["$filter"] = state.searchObj;
      }
    },
    removeItem: (state, action) => {
      const item = action.payload.item || {};
      delete state.searchObj[`${item.searchBy}`];
      state.search["$search"] = state.search["$filter"] = state.searchObj;
      if (item.isFilter) delete state.search["$filter"];
      else delete state.search["$search"];
    },
    clearSearch: (state) => {
      state.searchObj = {};
      state.search = {};
    },
    clearState: (state) => {
      state.watatu = [];
      state.data = [];
      state.message = null;
    },
    removeTicket: (state, action) => {
      state.watatu.splice(action.payload.index, 1);
    },
    changeWatatuAmount: (state, action) => {
      if (state.watatu.length != action.payload.numbers.length) {
        state.watatu = [];
        action.payload.numbers.forEach((element) => {
          state.watatu.push({ numbers: element, amount: 300 });
        });
      }
      if (!action.payload.noAmountChange) {
        const index = action.payload.numbers.findIndex((item) => {
          return arraysAreEqual(item, action.payload.item);
        });
        if (index !== -1) {
          state.watatu[index] = {
            numbers: action.payload.item,
            amount:
              action.payload.operation == "minus"
                ? state.watatu[index].amount - 100
                : state.watatu[index].amount + 100,
          };
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.result;
        action.payload.result.length == 0
          ? (state.message = "User not found")
          : "";
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  removeItem,
  addItem,
  clearState,
  changeWatatuAmount,
  removeTicket,
  clearSearch,
} = storeSlice.actions;
export default storeSlice.reducer;
