import { BULKSMSFEE, CLEAR } from "../actions/types";

const initialState = {
  fee: {},
};

const BulkReducer = (state = initialState, action) => {
  switch (action.type) {
    case BULKSMSFEE:
      return { ...state, fee: action.payload };
    case CLEAR:
      return { ...state, fee: {} };
    default:
      return state;
  }
};

export default BulkReducer;
