import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../translations/i18n";
import { useEffect, useState } from "react";
import DynamicIcon from "../../_common/DynamicIcon";
import { getNotification } from "../../../_services/auth.service";
import { useNavigate } from "react-router";
import { CLEARPAGE } from "../../../_redux/actions/types";
import { _clickHandler } from "../claim/GeneralClaim";
export const languageHandler = (setLang) =>
  useEffect(() => {
    const change = () => setLang(i18n.language);
    i18n.on("languageChanged", change);
    return () => {
      i18n.off("languageChanged", change);
    };
  }, [i18n]);
const Notifications = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotification());
    dispatch({ type: CLEARPAGE });
  }, []);
  const notification = useSelector(
    ({ NotificationReducer }) => NotificationReducer
  ).notifications;
  const [lang, setLang] = useState(i18n.language);
  languageHandler(setLang);

  return (
    <div className="notifications">
      {notification.map((i, key) => {
        if (i.type == "OPEN_CREDIT_REQUESTS_NOTIFICATION")
          return <PendingRequest i={i} key={key} />;
        else
          return (
            <ImageDisplay i={i} lang={lang} key={key} isAgent={props.isAgent} />
          );
      })}
    </div>
  );
};
const PendingRequest = ({ i }) => {
  const navigate = useNavigate();
  return (
    <div
      className="notification"
      onClick={() => navigate("/admin/creditRequest")}
    >
      <DynamicIcon
        prefix={i.icon.split(/(?=[A-Z])/)[0]}
        iconName={i.icon || "faBell"}
      />
      <h4>Open Credit Request</h4>
      <span className="badge bg-secondary">{i.count}</span>
    </div>
  );
};
const ImageDisplay = ({ i, lang, isAgent }) => {
  const [imageError, setImageError] = useState(false);
  const handleImageError = () => setImageError(true);
  return (
    <div>
      {imageError || !i.content?.imageURL ? (
        <TextDisplay i={i} lang={lang} isAgent={isAgent} />
      ) : (
        <div className="notification" style={{ padding: "10px" }}>
          <img
            src={i.content?.imageURL?.url}
            alt=""
            onError={handleImageError}
            style={{ width: "100%" }}
          />
        </div>
      )}
    </div>
  );
};

const TextDisplay = ({ i, lang, isAgent }) => {
  const navigate = useNavigate();
  return (
    <>
      {i.content && (
        <div
          className="notification"
          onClick={() =>
            (i.content.claim_id || i.content.transaction_id) &&
            _clickHandler(i.content, isAgent, navigate)
          }
        >
          <DynamicIcon
            prefix={i.content.icon.split(/(?=[A-Z])/)[0]}
            iconName={i.content.icon || "faBell"}
          />
          {i.content.subject && (
            <h4>{i.content.subject[lang] || i.content.subject["en"]} </h4>
          )}
          <span>
            {i.content.plain_text[lang] || i.content.plain_text["en"]}
          </span>
          {i.content.link && (
            <h6 className="mt-2">
              <a className="text-primary" href={i.content.link}>
                View link
              </a>
            </h6>
          )}
          {i.at_modified && (
            <b className="pull-right mt-2">
              {new Date(i.at_modified).toLocaleString("en-GB")}
            </b>
          )}
        </div>
      )}
    </>
  );
};
export default Notifications;
