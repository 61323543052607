import { Action } from "history";
import { TIMEWARNING } from "../actions/types";

const initialState = {
  timeIncorrect: false,
};

export const WarningReducer = (state = initialState, action) => {
  switch (action.type) {
    case TIMEWARNING:
      return { ...state, timeIncorrect: action.payload };
    default:
      return state;
  }
};
