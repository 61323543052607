import { filterFun, serviceHandler } from "../../common";
import {
  PASSBOOKCLAIM,
  TRANSACTIONCLAIM,
  CLAIMDETAILS,
  POSTSUCCESS,
} from "../types";
import { listHandler } from "./common";
const action = (data, url, dispatch, type, disableLoading) =>
  serviceHandler(
    type,
    url,
    dispatch,
    "post",
    data,
    null,
    null,
    { stayWhenError: true },
    { stayOnC: true },
    disableLoading
  );

/////////////////////////
//// ADMIN ACTIONS ////
/////////////////////////
export const listAdminClaims = (filters, data) => async (dispatch) =>
  listHandler(filters, "/claim/listAdminClaims", data, dispatch);

export const claimHistory = (filters, data) => async (dispatch) =>
  listHandler(filters, "/claim/adminClaimsHistory", data, dispatch);

export const refundAgent =
  ({ message, txn_id }) =>
  async (dispatch) =>
    action(
      { message, txn_id },
      "/transaction/refundToAgent?authtoken=",
      dispatch,
      POSTSUCCESS
    );

export const refundedToUs =
  ({ message, txn_id }) =>
  async (dispatch) =>
    action(
      { message, txn_id },
      "/transaction/markRefundedToUs?authtoken=",
      dispatch
    );
export const claimByUs =
  ({ message, txn_id }) =>
  async (dispatch) =>
    action({ message, txn_id }, "/transaction/claimByUs?authtoken=", dispatch);
export const claimRespond = (message, claim_id, status) => async (dispatch) =>
  action(
    { message, claim_id, status },
    "/claim/respond?authtoken=",
    dispatch,
    TRANSACTIONCLAIM
  );
export const seenByAdmin =
  ({ claim_id }) =>
  async (dispatch) =>
    action({ claim_id }, "/claim/seenByAdmin?authtoken=", dispatch);
export const assignAdmin =
  ({ claim_id, user_id }) =>
  async (dispatch) =>
    action(
      { claim_id, user_id },
      "/claim/assignAdmin?authtoken=",
      dispatch,
      null,
      true
    );
export const adminAddClaim =
  ({ message, transaction_id, status }, id) =>
  async (dispatch) => {
    const data = { message, transaction_id, status };
    if (id) data.for_user_id = id;
    action(data, "/claim/addAdminClaim?authtoken=", dispatch, TRANSACTIONCLAIM);
  };

/////////////////////////
//// AGENT actions ////
/////////////////////////
export const addClaim =
  ({ message, transaction_id, status }) =>
  async (dispatch) =>
    action(
      { message, transaction_id, status },
      "/claim/addClaim?authtoken=",
      dispatch,
      PASSBOOKCLAIM
    );
export const updateClaim =
  ({ message, claim_id, status }) =>
  async (dispatch) =>
    action(
      { message, claim_id, status },
      "/claim/updateClaim?authtoken=",
      dispatch,
      PASSBOOKCLAIM
    );
export const seenByAgent = (claim_id) => async (dispatch) =>
  action({ claim_id }, "/claim/seenByAgent?authtoken=", dispatch);
export const listAgentClaim = (filters, data) => async (dispatch) =>
  listHandler(filters, "/claim/listUserClaims", data, dispatch);
export const getClaimById = (id) => async (dispatch) =>
  serviceHandler(CLAIMDETAILS, `/claim/details?authtoken=`, dispatch, null, {
    id,
  });
