import React, { Component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLength from "../Agents/Report/PageLength";
import Pagination from "../Agents/Report/Pagination";
import { DownloadButton, MoneyDisplay } from "./common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  faCaretDown,
  faCaretUp,
  faListUl,
  faSortAmountDown,
  faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import { PassBooKInfo } from "../Agents/Report/Passbook";
import Search, {
  FilterInput,
  handleSearch,
  SearchDropDown,
  SearchInput,
} from "./Search";
import { removeItem } from "../../_redux/storeSlice";
import DatePicker from "./DateRangePicker";

const Sorting = ({ item, reportReducer }) => {
  let body = "";
  if (
    reportReducer.orderBy &&
    item.order &&
    reportReducer.orderBy == item.order
  ) {
    body = (
      <FontAwesomeIcon
        className="sort-btn"
        icon={
          reportReducer.orderDirection == "DESC"
            ? faSortAmountDown
            : faSortAmountUp
        }
      />
    );
  }
  return <>{body}</>;
};
/**
 *
 * @param {array} header
 * @param {Component} body
 * @returns
 */
export const Table = (props) => {
  const { t } = useTranslation();
  const [searchItem, setSearchItem] = useState([]);
  return (
    <>
      <SeachFilters searchItem={searchItem} setSearchItem={setSearchItem} />
      <table className="iteme-table">
        <thead>
          <TdHeader
            header={props.header}
            setOrderBy={props.setOrderBy}
            orderDirection={props.orderDirection}
            setOrderDirection={props.setOrderDirection}
            setSearchBy={props.setSearchBy}
            searchItem={searchItem}
            setSearchItem={setSearchItem}
          />
        </thead>
        <tbody>{props.body}</tbody>
      </table>
      {props.data && !props.data.length && (
        <h2 style={{ textAlign: "center", marginTop: "30px" }}>
          {t("NoDataFound")}
        </h2>
      )}
    </>
  );
};

const SeachFilters = (props) => {
  const dispatch = useDispatch();
  return (
    <div className="search-filters">
      {props.searchItem.map((key, i) => (
        <span key={i} className="search-filter">
          <small className="px-1"> {key.label}: </small>
          {key.value}
          <b
            className="close"
            onClick={() => {
              props.setSearchItem((current) =>
                current.filter((item) => item.label != key.label)
              );
              dispatch(removeItem({ item: key }));
            }}
          >
            &#x2715;
          </b>
        </span>
      ))}
    </div>
  );
};

const rightAligned = [
  "Amount",
  "Balance",
  "WHT",
  "Net",
  "Gross",
  "RWF",
  "%",
  "Fees",
  "Action",
  "Igiciro",
  "Igiciro Gisigaye",
  "Igikorwa",
  "Ayo ufite kuri konti",
  "Rusange",
  "Bwite",
  "remaining_amount",
  "penalties_per_day",
  "Penalty",
];
export const align = (item) => {
  let alignItem;
  rightAligned.forEach((element) => {
    if (item.toString().includes(element)) {
      alignItem = "right";
    }
  });
  return alignItem;
};
const TdHeader = (props) => {
  const { t } = useTranslation();
  const reportReducer = useSelector(({ reportReducer }) => reportReducer);
  const [id, setId] = useState();
  const [change, setChange] = useState(false);
  useEffect(() => {
    document.getElementById(id) && document.getElementById(id).focus();
  }, [change]);

  return (
    <>
      <tr>
        {props.header.map((item, i) => {
          return (
            <th key={i} style={{ textAlign: align(item.label || item) }}>
              <span
                onClick={() => handleSort(item.order, props)}
                className={`${item.order && "sort-btn"}`}
              >
                {t(`${item.label || item}`)}
                <Sorting item={item} reportReducer={reportReducer} />
              </span>
              {item.searchBy && (
                <>
                  <div className="table-search-popup" id={item.label}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        document.getElementById(item.label).style.display =
                          "none";
                      }}
                    >
                      {t(`${item.label}`)}{" "}
                      <span className="table-search-popup-button pull-right">
                        <FontAwesomeIcon
                          icon={faCaretUp}
                          onClick={() => {
                            document.getElementById(item.label).style.display =
                              "none";
                          }}
                        />
                      </span>
                    </span>
                    <Searching item={item} id={i} {...props} />
                  </div>
                  <span
                    className="table-search-button"
                    onClick={() => {
                      document.getElementById(item.label).style.display =
                        "block";
                      setId(i);
                      setChange(!change);
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretDown} className="mx-1" />
                  </span>
                </>
              )}
            </th>
          );
        })}
      </tr>
    </>
  );
};
export const Money = ({ amount, isPassBook, label }) => {
  amount = parseFloat(amount);
  return (
    <td
      style={{ textAlign: "right", whiteSpace: "nowrap" }}
      data-label={label || ""}
    >
      {isPassBook ? (amount > 0 ? "+" : "") : null}
      <MoneyDisplay amount={amount} suffix={"RWF"} />
    </td>
  );
};
const passBook_option = (t) => [
  t("Today"),
  t("Yesterday"),
  t("Last7Days"),
  t("ThisMonth"),
  t("LastMonth"),
  t("All"),
];
export const SelectDate = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <div className="pull-right">
      <div className="parent" onClick={() => setShow(!show)}>
        <span>{passBook_option(t)[props.currentSelected]}</span>
        {show && (
          <div className="child">
            {passBook_option(t).map((key, i) => {
              if (key != passBook_option(t)[props.currentSelected])
                return (
                  <div
                    key={i}
                    className="child-item"
                    onClick={() => {
                      props.changeDateHandler(i);
                      setShow(false);
                    }}
                  >
                    <span>{key}</span>
                    <br></br>
                  </div>
                );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
export const TableRow = (props) => {
  return (
    <>
      <h2 className="heading-title pull-left">
        <FontAwesomeIcon icon={faListUl} style={{ marginRight: "10px" }} />
        {props.title && props.title.toUpperCase()}
      </h2>
      {/* {!props.hideDate && <SelectDate {...props} />} */}
      {!props.hideDate && <DatePicker {...props} />}
      <>
        <div className="hide-on-desktop">
          {props.range && <TableNavigation {...props} />}
          <Search searchBy={props.searchBy} setSearchBy={props.setSearchBy} />
        </div>
        <Table {...props} />
        {props.range && <TableNavigation {...props} />}
      </>
    </>
  );
};
const handleSort = async (item, props) => {
  if (item) {
    const dir = props.orderDirection == "DESC" ? "ASC" : "DESC";
    props.setOrderBy && props.setOrderBy(item);
    props.setOrderDirection && props.setOrderDirection(dir);
  }
};
export default Table;

const TableNavigation = (props) => {
  return (
    <>
      {props.data.length ? (
        <div className="iteme-button-group">
          <Pagination
            currentPage={props.page}
            totalPages={props.range.length}
            pagingHandler={props.pagingHandler}
          />

          <PageLength
            pageSizeHandler={props.pageSizeHandler}
            pageSize={props.pageSize}
            disable={props.range.length == 1 ? true : false}
          />
          {props.statistic && <StatisticInfo statistic={props.statistic} />}
          {(props.start || props.allEnabled) && <DownloadButton {...props} icon={true} />}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const StatisticInfo = ({ statistic }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="parent pull-right" onClick={() => setShow(!show)}>
      <span>
        Total <MoneyDisplay amount={statistic.amount || statistic.total} />
      </span>
      {statistic.total && show ? <PassBooKInfo statistic={statistic} /> : ""}
    </div>
  );
};

const Searching = (props) => {
  const [searchValue, setSearchValue] = useState("");
  handleSearch(props.setSearchBy, props.item);

  let body = "";
  if (props.item.searchBy && !props.item.option)
    body = (
      <SearchInput
        {...props}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );
  if (props.item.searchBy && props.item.option)
    body = props.item.option.map((element, i) => {
      return (
        <div key={i}>
          <SearchDropDown element={element} {...props} />
        </div>
      );
    });
  if (props.item.isFilter)
    body = (
      <FilterInput
        {...props}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );
  return body;
};
