import { MTNPRODUCTS, OTPSENT, VALIDATEOTP } from "../actions/types";

const initialstate = {
  products: [],
  otpSent: false,
};

export const UserReducer = (state = initialstate, action) => {
  switch (action.type) {
    case MTNPRODUCTS:
      return { ...state, products: action.payload };
    case OTPSENT:
      return { ...state, otpSent: true };
    case VALIDATEOTP:
      return { ...state, otpValid: action.payload || false };
    default:
      return initialstate;
  }
};
