import React from "react";

const AboutRow = (props) => {
  let isIntro = false;
  if (
    props.title.includes("ITEME") ||
    props.title.includes("Privacy") ||
    props.title.includes("Terms")
  )
    isIntro = true;
  return (
    <div className="row">
      <div className="col">
        {isIntro ? (
          <h1 className="intro-header"> {props.title}</h1>
        ) : (
          <h3 className="terms-header">{props.title}</h3>
        )}
      </div>
      <div className="col">
        <p className="about-statement">{props.content}</p>
      </div>
    </div>
  );
};

export default AboutRow;
